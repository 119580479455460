import { Box, Text } from "flicket-ui";
import { atom, useAtom } from "jotai";
import { ContentModal } from "~components";
import { PlainTextRender } from "~features/nonseated-reservation/components/common/TextRenders/TextRenders";
import { ExtendedFile } from "~graphql/sdk";
import { getImageSrc } from "~lib";

export type Details = {
  name: string;
  description?: string;
  restrictions?: string;
  ticketImage?: ExtendedFile;
  addonImage?: ExtendedFile;
};

export const ticketDetailsAtom = atom<Details | undefined>(undefined);

export const TicketDetailsModal = () => {
  const [details, setDetails] = useAtom(ticketDetailsAtom);
  const ticketImage = details?.ticketImage ?? details?.addonImage;

  return (
    <ContentModal
      isOpen={!!details}
      // @ts-expect-error atom type issue while using strict:false
      setIsOpen={() => setDetails(undefined)}
      title={details?.name}
    >
      <>
        {details?.description ? (
          <>
            <Text fontSize={4} fontWeight="extraBold" color="N700">
              Description
            </Text>
            <PlainTextRender value={details?.description} variant="small" />
          </>
        ) : null}

        {ticketImage && (
          <Box
            backgroundSize="cover"
            backgroundPosition="center"
            style={{
              backgroundImage: `url(${getImageSrc(ticketImage)})`,
            }}
            height="320px"
            mt={3}
          />
        )}
        {details?.restrictions ? (
          <>
            <Text fontSize={4} fontWeight="extraBold" color="N700" mt={2}>
              Restrictions
            </Text>
            <Text fontSize={2} color="N500">
              {details?.restrictions}
            </Text>
          </>
        ) : null}
      </>
    </ContentModal>
  );
};
