import { Box, Text, Stack, TertiaryButton } from "flicket-ui";

import { ReleaseType } from "~graphql/sdk";
import { CreateOrderFn, useOrganization } from "~hooks";
import {
  INormalisedData,
  IReleaseTicket,
  IReleaseZone,
} from "~features/nonseated-reservation/nonseated-reservation.types";
import { ExperienceSalesFooter } from "~features/nonseated-reservation/components/ExperienceSalesFooter/ExperienceSalesFooter";
import { ReservationList } from "~components/reservation/non-seated";
import HeaderAlertBanner from "~components/common/common.HeaderAlertBanner";
import { Footer, Menu, PageContainer } from "~components";
import { useIntersectZone } from "./useIntersection.hook";
import Image from "~components/common/Image";
import { StickyZoneTabs } from "./StickyZoneTabs";
import { UserContext } from "~context";
import { ReactNode, useContext } from "react";
import {
  ImportantNotice,
  R18Notice,
} from "~components/reservation/non-seated/non-seated.components";
import { CONTENT_MAX_WIDTH } from "./non-seated.constants";
import {} from "~components/reservation/non-seated/non-seated.helpers";
import { useNonSeatedReservationStore } from "~features/nonseated-reservation/store/non-seated-reservation-store";
import { useGetTicketsSoldForUser } from "~components/reservation/non-seated/useGetTicketsSoldForUser.hook";
import { useFilterVisibleZonesAndTickets } from "~components/reservation/non-seated/useFilterVisibleZonesAndTickets.hook";
import { removeUnsyncedQueryParams } from "~lib/state-stores/appStore/action.removeUnsyncedQueryParams";
import { MoreDatesButton } from "./MoreDatesButton";
import { DOT_SEPARATOR } from "~lib/helpers/constants";
import Router from "next/router";
import { omit } from "lodash";

type NonSeatedProps = {
  data: INormalisedData;
  zones: IReleaseZone[];
  addons: IReleaseTicket[];
  createOrder: CreateOrderFn;
  isMembership: boolean;
  isAdmin: boolean;
  releaseId?: string;
  membershipId?: string;
  releaseNotes: string;
  allowPublicComp: boolean;
  isResaleRelease: boolean;
  releaseType: ReleaseType;
  requiresAuth: boolean;
  calendarView?: boolean;
};

export const NonSeatedReservationPage = ({
  addons,
  data,
  zones,
  createOrder,
  isMembership = false,
  isAdmin,
  releaseId,
  membershipId,
  releaseNotes,
  allowPublicComp,
  isResaleRelease,
  releaseType,
  requiresAuth = false,
}: NonSeatedProps) => {
  const { hasFeature } = useOrganization();

  const calendarView = hasFeature("eventCalendarHomepage");

  const { authState } = useContext(UserContext);
  const isPOSRelease = releaseType === ReleaseType.Pos;

  const selectedTickets = [];
  useNonSeatedReservationStore((state) => state.selectedTicketTypes[data?.id]);

  const { ticketsSoldForUser } = useGetTicketsSoldForUser({
    data,
    isMembership,
    isLoggedIn: authState === "authenticated",
  });

  const visibleZonesAndTickets = useFilterVisibleZonesAndTickets({
    zones,
    selectedTickets,
    ticketsSoldForUser,
    isPresaleRelease: releaseType === ReleaseType.Presale,
    isAdmin,
  });

  // This intersection observer is used to remove the zoneId query param
  // when the user scrolls back to the top of the page.
  const { ref: intersectionRef } = useIntersectZone(
    {
      onIntersectTop: () => {
        removeUnsyncedQueryParams(["zoneId"]);
      },
    },
    {
      threshold: [0, 1],
    }
  );

  const showStickyTabs =
    (visibleZonesAndTickets?.length ?? 0) + (addons?.length ?? 0) > 1;

  const maxWidth = calendarView ? "none" : CONTENT_MAX_WIDTH;

  return (
    <Box width={1} pt={calendarView ? 9 : 0}>
      {calendarView && <Menu sticky={true} />}
      <Wrapper data={data} calendarView={calendarView}>
        <Box ref={intersectionRef}>
          <Box
            zIndex="banner"
            css={`
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
            `}
          >
            <HeaderAlertBanner />
          </Box>

          {!isPOSRelease && <Box pt={{ sm: 3 }} />}

          {!isPOSRelease && !calendarView && (
            <Box
              width={1}
              px={{ _: 0, sm: 2 }}
              maxW={{ sm: maxWidth }}
              bg="white"
              margin={"auto" as any}
              mb={4}
              id="event-banner"
            >
              <Box position="relative">
                {data.banner && (
                  <Image
                    image={data.banner}
                    alt={data.name}
                    width={{ _: "100%" }}
                    borderRadius={{ sm: "sm" }}
                    backgroundColor="N200"
                  />
                )}
              </Box>
            </Box>
          )}

          <Box
            width={1}
            px={2}
            maxW={{ sm: maxWidth }}
            bg="white"
            margin={"auto" as any}
            mb={2}
            mt={isPOSRelease ? 5 : 0}
          >
            <Stack gap={3} flexWrap="wrap">
              <Box flexGrow={1}>
                <Text variant="header.L" mb="1/2">
                  {data.name}
                </Text>
                {!isPOSRelease && (
                  <Text variant="regular">
                    {data.displayDate
                      ? `${data.displayDate} ${DOT_SEPARATOR} `
                      : ``}{" "}
                    {data.location}
                  </Text>
                )}
                {calendarView && (
                  <MoreDatesButton>
                    <Text variant="regular">More dates</Text>
                  </MoreDatesButton>
                )}
              </Box>
              <TertiaryButton
                alignSelf="flex-start"
                px={2}
                py={1}
                borderColor="N300"
                borderRadius="xs"
                to={{
                  pathname: `/${
                    data.type === "event" ? "events" : "memberships"
                  }/${data.id}`,
                  query: {
                    ...omit(Router.query, ["eventId", "membershipId"]),
                  },
                }}
              >
                <Text variant="header.XS">Event details</Text>
              </TertiaryButton>
            </Stack>

            <Box>
              {data.isR18 && !isPOSRelease && <R18Notice />}
              {!isPOSRelease && (
                <ImportantNotice value={releaseNotes ?? data.importantNotice} />
              )}
            </Box>
          </Box>
        </Box>

        {showStickyTabs && (
          <StickyZoneTabs
            zones={visibleZonesAndTickets}
            addons={addons}
            isPOSRelease={isPOSRelease}
            calendarView={calendarView}
          />
        )}

        <Box
          width={1}
          px={2}
          maxW={{ sm: maxWidth }}
          bg="white"
          margin={"auto" as any}
        >
          <ReservationList
            addons={addons}
            data={data}
            zones={zones}
            createOrder={createOrder}
            isMembership={isMembership}
            releaseNotes={releaseNotes}
            isDigital={data.isDigital}
            allowPublicComp={allowPublicComp}
            isResaleRelease={isResaleRelease}
            releaseType={releaseType}
            requiresAuth={requiresAuth}
          />
        </Box>

        <ExperienceSalesFooter
          id={data.id}
          releaseId={releaseId}
          membershipId={membershipId}
          hideFooterDuringMode={["no-selection"]}
        />

        {!isPOSRelease && !calendarView && (
          <Footer narrow={true} display={{ _: "flex", md: "flex" }} />
        )}
      </Wrapper>
    </Box>
  );
};

function Wrapper({
  children,
  data,
  calendarView,
}: {
  children: ReactNode;
  data: INormalisedData;
  calendarView: boolean;
}) {
  if (!calendarView) return <>{children}</>;
  return (
    <PageContainer
      backgroundImage={data.backgroundImage}
      backgroundImageFullPage={data.backgroundImageFullPage}
      bannerImage={data.banner}
      titleText={data.name}
      footer={<Footer />}
      calendarView={true}
      containerProps={{
        position: "relative",
      }}
    >
      {children}
    </PageContainer>
  );
}
