import { Flex } from "flicket-ui";

import { Footer, Layout, PageContainer } from "~components";
import { ReleaseType } from "~graphql/sdk";
import { CreateOrderFn } from "~hooks";
import { ReservationList } from "./non-seated";
import {
  INormalisedData,
  IReleaseTicket,
  IReleaseZone,
} from "~features/nonseated-reservation/nonseated-reservation.types";

type NonSeatedProps = {
  data: INormalisedData;
  zones: IReleaseZone[];
  addons: IReleaseTicket[];
  createOrder: CreateOrderFn;
  isMembership: boolean;
  isAdmin: boolean;
  releaseId: string;
  releaseNotes: string;
  allowPublicComp: boolean;
  isResaleRelease: boolean;
  releaseType: ReleaseType;
  requiresAuth: boolean;
};

export const NonSeated = ({
  addons,
  data,
  zones,
  createOrder,
  isMembership = false,
  isAdmin,
  releaseId,
  releaseNotes,
  allowPublicComp,
  isResaleRelease,
  releaseType,
  requiresAuth = false,
}: NonSeatedProps) => {
  const hasFullPageBackground = !!data?.backgroundImageFullPage;

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex
        flexDirection="column"
        flex={1}
        maxH={{
          _: isAdmin ? "calc(100vh - 96px)" : "calc(100vh - 64px)",
          md: "unset",
        }}
      >
        <Layout pt={"0 !important" as any} layoutType="overlay">
          <PageContainer
            backgroundImage={data.backgroundImage}
            backgroundImageFullPage={data.backgroundImageFullPage}
            bannerImage={data.banner}
            titleText={data.description}
            footer={<Footer />}
            title={null}
            containerProps={{
              px: 0,
            }}
          >
            <ReservationList
              addons={addons}
              data={data}
              zones={zones}
              createOrder={createOrder}
              isMembership={isMembership}
              releaseNotes={releaseNotes}
              isDigital={data.isDigital}
              allowPublicComp={allowPublicComp}
              isResaleRelease={isResaleRelease}
              releaseType={releaseType}
              requiresAuth={requiresAuth}
            />
          </PageContainer>
        </Layout>
      </Flex>
    </Flex>
  );
};
