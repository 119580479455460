import { Box, Flex, Stack, Text } from "flicket-ui";

import {
  IReleaseTicket,
  IReleaseZone,
} from "~features/nonseated-reservation/nonseated-reservation.types";
import { useShowHeaderBanner } from "~components/common/common.HeaderAlertBanner";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import StickySection from "~components/common/StickySection";
import { SCROLL_IN_PROGRESS } from "./useIntersection.hook";
import styled from "styled-components";
import { useAccount } from "~hooks";
import { CONTENT_MAX_WIDTH } from "./non-seated.constants";
import { updateUnsyncedQueryParams } from "~lib/state-stores/appStore/action.updateUnsyncedQueryParams";
import { useAppStore } from "~lib/state-stores/appStore/app-store";

export const TABS_HEIGHT = 60;

export function StickyZoneTabs({
  zones,
  addons,
  isPOSRelease,
  calendarView = false,
}: {
  zones: IReleaseZone[];
  addons: IReleaseTicket[];
  isPOSRelease: boolean;
  calendarView: boolean;
}) {
  const router = useRouter();
  const { scrollToZone } = useScrollToZone();
  const { activeBannerHeight } = useShowHeaderBanner();
  const { authState } = useAccount();

  const zoneIdQueryParam = useAppStore(
    (state) => state.unsyncedQueryParams.zoneId
  );

  function handleClick(id: string) {
    scrollToZone(id);
    updateUnsyncedQueryParams({ zoneId: id });
  }

  // Handle initial scroll to zone on load if zoneId is in the query. A little complex:
  // 1. we need to wait for the auth state to be known before scrolling so
  //   we can take into account any header banner heights and
  // 2. we want to set the scroll in progress class to prevent the intersection observer
  //   removing the zoneId prop from the header on page load.
  useEffect(() => {
    if (router?.query.zoneId) {
      document.body.classList.add(`${SCROLL_IN_PROGRESS}-auto-scroll`);

      if (authState !== "authenticating") {
        scrollToZone(router?.query.zoneId as string, () => {
          document.body.classList.remove(`${SCROLL_IN_PROGRESS}-auto-scroll`);
        });
      }
    }
  }, [authState, router?.query.zoneId]);

  // If the zone Id has changed, make sure the tab is selected and in view
  useEffect(() => {
    if (zoneIdQueryParam) {
      const tabWrapper = document.getElementById("sticky-zone-tabs");
      const tabs: HTMLDivElement = tabWrapper.querySelector(".scroller");
      const tabsWidth = tabs?.offsetWidth ?? 0;
      const tabsScrollWidth = tabs?.scrollWidth ?? 0;

      const isOverflowing = tabsScrollWidth > tabsWidth;

      // Center the active tab if tabs are overflowing
      if (isOverflowing) {
        const activeTabEl: HTMLDivElement = tabWrapper.querySelector(
          `[data-active="true"]`
        );

        if (!activeTabEl) return;

        const tabWidth = activeTabEl.offsetWidth;
        const tabLeft = activeTabEl.offsetLeft;
        const tabCenter = tabLeft + tabWidth / 2;
        const containerWidth = tabs.offsetWidth;
        const containerCenter = containerWidth / 2;
        const newScrollLeft = tabCenter - containerCenter;

        tabs.scrollTo({
          behavior: "smooth",
          left: newScrollLeft,
        });
      }
    }
  }, [zoneIdQueryParam]);

  return (
    <StickySection offset={activeBannerHeight}>
      {({ isSticky }) => (
        <Box
          id="sticky-zone-tabs"
          boxShadow={isSticky ? "card" : undefined}
          backgroundColor="white"
          mt={isPOSRelease ? 0 : { _: 3, sm: 6 }}
          mb={{ _: 2, sm: 4 }}
          ml={
            calendarView && isSticky
              ? { _: "-16px" as any, md: "-32px" as any }
              : undefined
          }
          mr={
            calendarView && isSticky
              ? { _: "-16px" as any, md: "-32px" as any }
              : undefined
          }
        >
          <Box
            maxW={{ sm: calendarView ? "none" : CONTENT_MAX_WIDTH }}
            margin={"auto" as any}
          >
            <Box
              height={`${TABS_HEIGHT}px`}
              mx={{ xs: 2 }}
              position="relative"
              css={`
                :before {
                  content: "";
                  position: absolute;
                  bottom: 0px;
                  left: 0;
                  right: 0;
                  height: 1px;
                  background-color: ${(p) => p.theme.colors.N200};
                }
              `}
            >
              <Stack
                className="scroller"
                direction="horizontal"
                gap={1}
                w="100%"
                overflowX="auto"
                position="relative"
                zIndex={1}
                css={`
                  ::-webkit-scrollbar {
                    display: none;
                  }
                `}
              >
                {zones.map((zone, i) => {
                  const isSelected =
                    (!zoneIdQueryParam && i === 0) ||
                    zoneIdQueryParam === zone.id;

                  return (
                    <TabItem
                      key={zone.id}
                      name={zone.name}
                      isSelected={isSelected}
                      onClick={() => {
                        handleClick(zone.id);
                      }}
                    />
                  );
                })}

                {addons?.length > 0 && (
                  <TabItem
                    key={"addons"}
                    name="Add-ons"
                    isSelected={zoneIdQueryParam === "add-ons"}
                    onClick={() => handleClick("add-ons")}
                  />
                )}
              </Stack>
            </Box>
          </Box>
        </Box>
      )}
    </StickySection>
  );
}

const StyledText = styled(Text)`
  text-wrap: nowrap;
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) {
    /* Target only mouse pointer devices so mobile doesnt get sticky hover effects
     https://stackoverflow.com/questions/17233804/how-to-prevent-sticky-hover-effects-for-buttons-on-touch-devices/28058919#28058919 
    */
    &:hover {
      color: ${(p) => p.theme.colors.N600};
    }
  }
`;

function TabItem({
  onClick,
  name,
  isSelected,
}: {
  onClick: () => void;
  name: string;
  isSelected: boolean;
}) {
  return (
    <Flex
      onClick={onClick}
      alignItems="flex-end"
      flexWrap="nowrap"
      data-active={isSelected}
      position="relative"
      height={`${TABS_HEIGHT}px`}
    >
      <StyledText
        variant="header.XS"
        cursor="pointer"
        py={2}
        px={1}
        borderBottom="3px solid"
        borderColor={isSelected ? "N800" : "transparent"}
      >
        {name}
      </StyledText>
    </Flex>
  );
}

function useScrollToZone() {
  const { activeBannerHeight } = useShowHeaderBanner();
  const [isScrolling, setScrolling] = useState(false);

  function scrollToZone(id: string, onComplete?: () => void) {
    setScrolling(true);
    const className = `${SCROLL_IN_PROGRESS}-${id}`;

    if (document.body.classList.contains(className)) return;

    document.body.classList.add(className);

    const zoneEl = document.getElementById(id);

    if (!zoneEl) return;

    const offset = activeBannerHeight + (TABS_HEIGHT - 2);

    const top =
      window.scrollY +
      document.getElementById(id)?.getBoundingClientRect().top -
      offset;

    window.scrollTo({
      behavior: "smooth",
      top,
    });

    function handleScrollEnd() {
      document.body.classList.remove(className);

      setScrolling(false);

      onComplete?.();
    }

    // Since "scrollend" event has limited support use an arbitrary timeout
    // to simulate page scroll finish, rather than relying on scroll events.
    setTimeout(handleScrollEnd, 1500);
  }

  return {
    isScrolling,
    scrollToZone,
  };
}
