import { useMemo } from "react";
import {
  IReleaseTicket,
  IReleaseZone,
} from "~features/nonseated-reservation/nonseated-reservation.types";
import {
  MembershipsSoldForUserQuery,
  TicketsSoldForUserQuery,
} from "~graphql/sdk";
import { isVisible } from "./non-seated.helpers";

export function useFilterVisibleZonesAndTickets({
  zones,
  selectedTickets = [],
  ticketsSoldForUser,
  isPresaleRelease,
  isAdmin,
}: {
  zones: IReleaseZone[];
  selectedTickets: IReleaseTicket[];
  ticketsSoldForUser:
    | TicketsSoldForUserQuery["ticketsSoldForUser"]
    | MembershipsSoldForUserQuery["membershipsSoldForUser"];
  isPresaleRelease: boolean;
  isAdmin: boolean;
}): IReleaseZone[] {
  return useMemo(() => {
    return zones
      ?.map((zone: IReleaseZone) => {
        if (!zone.isActive) {
          return null;
        }

        const visibleTicketTypes = zone.ticketTypes.filter((ticketType) => {
          if (!ticketType.isActive && !isPresaleRelease) {
            return false;
          }

          if (
            !isVisible(selectedTickets, ticketType, ticketsSoldForUser) &&
            !isAdmin
          ) {
            return false;
          }

          return true;
        });

        return {
          ...zone,
          ticketTypes: visibleTicketTypes,
        };
      })
      .filter((zone) => {
        if (!zone) return false;
        const hasGallery = zone.imageGallery?.length > 0;
        const hasDescription = !!zone.description;
        const hasTicketTypes = zone.ticketTypes?.length > 0;

        return hasGallery || hasDescription || hasTicketTypes;
      });
  }, [
    zones?.length,
    JSON.stringify(selectedTickets),
    ticketsSoldForUser?.length,
    isPresaleRelease,
    isAdmin,
  ]);
}
