import { INormalisedData } from "~features/nonseated-reservation/nonseated-reservation.types";
import {
  MembershipsSoldForUserDocument,
  TicketsSoldForUserDocument,
} from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";

export function useGetTicketsSoldForUser({
  isMembership,
  isLoggedIn,
  data,
}: {
  isMembership: boolean;
  isLoggedIn: boolean;
  data: INormalisedData;
}) {
  const { data: ticketsSoldForUserData } = useQuery(
    isLoggedIn && data?.id && !isMembership ? TicketsSoldForUserDocument : null,
    {
      eventId: data?.id,
    }
  );

  const { data: membershipsSoldForUserData } = useQuery(
    isLoggedIn && data?.id && isMembership && MembershipsSoldForUserDocument,
    {
      membershipId: data?.id,
    }
  );

  const eventTicketsSoldForUser = ticketsSoldForUserData?.ticketsSoldForUser;

  const membershipsSoldForUser =
    membershipsSoldForUserData?.membershipsSoldForUser;

  const ticketsSoldForUser =
    eventTicketsSoldForUser ?? membershipsSoldForUser ?? [];

  return {
    eventTicketsSoldForUser,
    membershipsSoldForUser,
    ticketsSoldForUser,
  };
}
