import { Box, system, SystemProps } from "flicket-ui";
import styled from "styled-components";
import { FixedRatioImage, IFixedRatioImageProps, Icon } from "~components";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { CaretLeft, CaretRight } from "phosphor-react";
import { ImageGalleryFragmentFragment } from "~graphql/sdk";
import { pick } from "@styled-system/props";
import uniqueId from "lodash/uniqueId";

type SliderProps = SystemProps & {
  initialSlideKey?: number | undefined;
  imageGallery: ImageGalleryFragmentFragment[];
  aspectRatio?: IFixedRatioImageProps["aspectRatio"];
  hideNavigation?: boolean;
  imageProps?: Partial<Omit<IFixedRatioImageProps, "color" | "fill">>;
  navigationSize?: "sm" | "lg";
  swiperProps?: any;
};

const StyledSwiper = styled(Box)<{ $size: SliderProps["navigationSize"] }>`
  --swiper-pagination-bullet-horizontal-gap: 3px;
  --swiper-pagination-bottom: 4px;
  position: relative;

  .swiper {
    background-color: ${({ theme }) => theme.colors.N200};

    position: relative;
    min-height: 100%;

    .swiper-slide {
      /* https://github.com/nolimits4web/swiper/issues/3599 */
      width: 100% !important;

      img {
        position: relative;

        &::after {
          content: "";

          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            180deg,
            transparent 80%,
            rgba(0, 0, 0, 0.25)
          );
          pointer-events: none;
          z-index: 999;
        }
      }
    }

    .swiper-pagination-fraction {
      background-color: rgba(34, 34, 34, 0.66);
      border-radius: 4px;
      font-weight: 600;
      line-height: 1.5;
      padding: 3px 10px;
      display: flex;
      width: auto;
      left: auto;
      right: 16px;
      bottom: 12px;

      color: white;
      font-size: ${({ theme }) => theme.fontSizes[1]};

      .swiper-pagination-current {
        padding-right: 4px;
      }
      .swiper-pagination-total {
        padding-left: 4px;
      }
    }

    .swiper-pagination-bullet {
      transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      opacity: 0.6;
      background-color: white;
      width: 6px;
      height: 6px;

      box-shadow: ${({ theme }) => theme.shadows.card};

      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }

    .swiper-wrapper {
      align-items: center;
    }

    img {
      /* filter: brightness(100%); */
    }

    .slider-navigation {
      -webkit-tap-highlight-color: transparent;
      z-index: 1000;
      cursor: pointer;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transform-origin: center;

      width: 40px;
      height: 40px;
      right: 12px;

      background: white;

      width: ${(p) => (p.$size === "sm" ? 32 : 52)}px;
      height: ${(p) => (p.$size === "sm" ? 32 : 52)}px;

      border-radius: 99px;
      opacity: ${(p) => (p.$size === "sm" ? 0 : 0.8)};

      &::after {
        content: "";
      }

      box-shadow: ${({ theme }) => theme.shadows.card};

      &:hover {
        transition: all 0.2s;
        opacity: 1;
        transform: scale(1.03) translateY(-50%);
      }
    }

    .prev-slide-button {
      left: 12px;
    }
  }

  .swiper-button-disabled {
    visibility: hidden;
    pointer-events: none;
  }

  ${system}
`;

const Overlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, transparent 80%, rgba(0, 0, 0, 0.25));
  pointer-events: none;
  z-index: 1;
`;

export const ImageSlider = ({
  initialSlideKey = 0,
  imageGallery,
  aspectRatio = "3 / 2",
  hideNavigation = false,
  imageProps = {},
  navigationSize = "lg",
  swiperProps,
  ...props
}: SliderProps) => {
  const showNavigation = imageGallery?.length > 1 || !hideNavigation;
  const id = uniqueId("swiper-id-");

  const params = {
    modules: [Navigation, Pagination],
    pagination: true,
    initialSlide: initialSlideKey,
    slidesPerView: 1,
    allowTouchMove: true,
    navigation: showNavigation && {
      nextEl: `#${id} .next-slide-button`,
      prevEl: `#${id} .prev-slide-button`,
    },
    lazyPreloadPrevNext: 2,
  };

  return (
    <>
      <StyledSwiper
        id={id}
        overflow="hidden"
        {...pick(props)}
        $size={navigationSize}
      >
        <Swiper {...params} {...swiperProps}>
          {imageGallery?.map((item) => (
            <SwiperSlide key={item.id}>
              <FixedRatioImage
                src={item.original?.src}
                metaData={item.original?.metaData}
                alt={item.title}
                aspectRatio={aspectRatio}
                width={{ _: "100%" }}
                overflow="hidden"
                objectFit="contain"
                useNearFitCover={true}
                {...imageProps}
              />
            </SwiperSlide>
          ))}
          {imageGallery?.length > 1 && <Overlay />}
          {showNavigation && <SliderNavigation />}
        </Swiper>
      </StyledSwiper>
    </>
  );
};

function SliderNavigation() {
  return (
    <>
      <Box
        alignItems="center"
        justifyContent="center"
        className="slider-navigation prev-slide-button"
        display={{ _: "none", sm: "flex" }}
        onClick={(e) => {
          // prevents the click from triggering events on the parent.
          e.stopPropagation();
        }}
      >
        <Icon color="N800" icon={<CaretLeft size={16} weight="bold" />} />
      </Box>
      <Box
        alignItems="center"
        justifyContent="center"
        className="slider-navigation next-slide-button"
        display={{ _: "none", sm: "flex" }}
        onClick={(e) => {
          // prevents the click from triggering events on the parent.
          e.stopPropagation();
        }}
      >
        <Icon color="N800" icon={<CaretRight size={16} weight="bold" />} />
      </Box>
    </>
  );
}
