import { Price } from "flicket-ui";
import { IReleaseTicket } from "~features/nonseated-reservation/nonseated-reservation.types";
import { FeeType } from "~graphql/sdk";

export interface IPriceWithFee {
  price: number;
  showFees: boolean;
  isPercentage?: boolean;
  isSoldOut?: boolean;
  fee?: number;
  stadiumLevyFee?: number;
}

const PriceWithFee = ({
  price,
  showFees = false,
  isPercentage = false,
  isSoldOut = false,
  fee = 0,
  stadiumLevyFee = 0,
}: IPriceWithFee) => {
  if (isSoldOut) return <>Sold out</>;

  if (!price) return <>Free</>;

  if (!showFees || (fee <= 0 && stadiumLevyFee <= 0)) {
    return <Price price={price} />;
  }

  const FeeComponent = ((price: number) => {
    const Component = isPercentage ? (
      <Price price={price * (fee / 100) + stadiumLevyFee} />
    ) : (
      <Price price={fee + stadiumLevyFee} />
    );

    return <>{Component} Fees</>;
  })(price);

  return (
    <>
      <Price price={price} /> {"\u002B"} {FeeComponent}
    </>
  );
};

export function renderFee(ticket: IReleaseTicket) {
  if (ticket.ticketFee <= 0 && ticket.stadiumLevyFee <= 0) {
    return null;
  }

  const FeePrice =
    ticket.ticketFeeType === FeeType.Percentage ? (
      <Price
        price={ticket.price * (ticket.ticketFee / 100) + ticket.stadiumLevyFee}
      />
    ) : (
      <Price price={ticket.ticketFee + ticket.stadiumLevyFee} />
    );

  return (
    <>
      {"\u002B"}
      {FeePrice} fee
    </>
  );
}

function Fee({
  ticket,
  showFees,
}: {
  showFees: boolean;
  ticket: IReleaseTicket;
}) {
  if (!showFees) return null;
  return renderFee(ticket);
}

PriceWithFee.Fee = Fee;

export default PriceWithFee;
