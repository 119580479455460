import { Flex, Link, Text } from "flicket-ui";
import { useAtom } from "jotai";
import flatten from "lodash/flatten";
import startCase from "lodash/startCase";
import { useRouter } from "next/router";
import { ReactNode, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { BaseContext } from "~context";
import { isBNPLIntegrationType } from "~features/checkout/checkout.types";
import {
  INormalisedData,
  IReleaseTicket,
  IReleaseZone,
} from "~features/nonseated-reservation/nonseated-reservation.types";
import {
  setNonSeatedStoreState,
  Ttotal,
  useNonSeatedReservationStore,
} from "~features/nonseated-reservation/store/non-seated-reservation-store";
import useActiveReferralCampaign from "~features/referrals/useActiveReferralCampaign";
import {
  IntegrationType,
  LandingPage,
  ReleaseType,
  TaxCalculationMethod,
  TicketTypeKind,
} from "~graphql/sdk";
import {
  CreateOrderFn,
  useAccount,
  useIsMobile,
  useNonSeatedMultibuy,
  useOrganization,
  useReferral,
} from "~hooks";
import { googleAnalyticsReleaseAtom } from "~hooks/useGoogleAnalyticsPageView";
import {
  calculateMultibuyDiscount,
  calculateTotal,
  showToast,
  trackViewItemList,
} from "~lib";
import {
  AttributeNames,
  InteractionNames,
} from "~telemetry/enums/AttributeNames";
import useCaptureClick from "~telemetry/traces/useCaptureClick";
import * as actions from "~features/nonseated-reservation/store/actions";
import { isAvailableForSelection } from "~components/reservation/non-seated/non-seated.helpers";
import { useFilterVisibleZonesAndTickets } from "~components/reservation/non-seated/useFilterVisibleZonesAndTickets.hook";
import { useGetTicketsSoldForUser } from "~components/reservation/non-seated/useGetTicketsSoldForUser.hook";

export interface INonSeatedProps {
  data: INormalisedData;
  zones: IReleaseZone[];
  addons: IReleaseTicket[];

  createOrder: CreateOrderFn;
  isMembership: boolean;
  releaseType: ReleaseType;
  releaseNotes: string;
  isDigital: boolean;
  isResaleRelease?: boolean;
  allowPublicComp?: boolean;
  requiresAuth: boolean;
}

export type TSoldOutWaitlistMessage = ({
  zoneName,
}: {
  zoneName: string;
}) => JSX.Element;

const StyledLink = styled.a`
  text-decoration: underline;
`;

export const useViewModel = (props: INonSeatedProps) => {
  const {
    data,
    addons: viewableAddons = [],
    zones = [],
    createOrder,
    isMembership,
    releaseNotes,
    isDigital,
    isResaleRelease,
    releaseType,
    allowPublicComp,
    requiresAuth,
  } = props;

  const { t } = useOrganization();
  const { activeReferralCampaign } = useActiveReferralCampaign(data.id);
  const router = useRouter();
  const { isAdmin, isPOS, authState, user } = useAccount();

  const selectedTicketTypes = useNonSeatedReservationStore(
    (state) => state.selectedTicketTypes[data.id]
  );

  const isStoreSubmitting = useNonSeatedReservationStore(
    (state) => state.isSubmitting
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [delayedIsSubmitting, setDelayedSubmitting] = useState(false);
  const { organization, isSinglePage } = useContext(BaseContext);
  const { hasFeature } = useOrganization();
  const [importantNotice, setImportantNotice] = useState(releaseNotes);
  const [alertError, setAlertError] = useState<
    { title: string; message: string | ReactNode } | undefined
  >();
  const ticketVariant = isDigital ? "pass code" : "ticket";
  const isMobile = useIsMobile();
  const { calculateReferralDiscount } = useReferral();

  const selectedTickets =
    selectedTicketTypes?.filter((i) => i.type !== "addon") ?? [];
  const selectedAddons =
    selectedTicketTypes?.filter((i) => i.type === "addon") ?? [];
  const userCredits = user?.credits ?? 0;

  const bnplGateways = data?.gateways?.filter(
    (gateway) => !!gateway && isBNPLIntegrationType(gateway.type)
  );

  const stripeAfterpayGateway = data?.gateways?.find(
    (gateway) =>
      gateway.type === IntegrationType.PaymentStripe && gateway.bnplEnabled
  );

  const showHiddenFees = hasFeature("showHiddenFees");

  useEffect(() => {
    let timer: NodeJS.Timeout = undefined;
    if (isSubmitting || isStoreSubmitting) {
      timer = setTimeout(() => {
        setDelayedSubmitting(true);
      }, 2000);
    } else {
      setDelayedSubmitting(false);
    }
    return () => timer && clearTimeout(timer);
  }, [isSubmitting, isStoreSubmitting]);

  useEffect(() => {
    releaseNotes
      ? setImportantNotice(releaseNotes)
      : setImportantNotice(data?.importantNotice);
  }, [releaseNotes]);

  const [release] = useAtom(googleAnalyticsReleaseAtom);

  const { ticketsSoldForUser } = useGetTicketsSoldForUser({
    data,
    isMembership,
    isLoggedIn: authState === "authenticated",
  });

  const captureClick = useCaptureClick({
    eventId: !isMembership && data?.id,
    forwardToNest: true,
    pageName: "ticket-reservation-page",
    attributes: {
      [AttributeNames.IS_MEMBERSHIP]: isMembership,
    },
  });

  /** @note because GA multibuy and non GA multibuy differ too much we have a new hook */
  const { activePromotions } = useNonSeatedMultibuy(
    data?.multiBuyPromotions,
    selectedTickets.map((s, key) => ({
      ...s,
      id: `${s.id}-${key}`,
      [isMembership ? "membershipType" : "ticketType"]: { id: s.id },
    }))
  );

  const allTicketTypes = flatten(zones.map((zone) => zone.ticketTypes)).filter(
    (ticketType) => {
      if (
        ticketType.kind !== TicketTypeKind.Standard &&
        !(isAdmin || allowPublicComp)
      ) {
        return false;
      }

      return ticketType.isActive;
    }
  );

  const allTicketTypesAndAddons = [...viewableAddons, ...allTicketTypes];
  const selectedTicketTypesAndAddons = [...selectedTickets, ...selectedAddons];

  const multibuyDiscount = selectedTickets?.every(
    (seat) => seat.zoneId === selectedTickets?.[0]?.zoneId
  )
    ? calculateMultibuyDiscount(
        activePromotions[0],
        allTicketTypesAndAddons,
        zones?.find((zone) => zone.name === selectedTickets?.[0]?.zoneId)
          ?.ticketTypes
      )
    : 0;

  const calculateTotals = (itemsToAdd: IReleaseTicket[]): Ttotal => {
    const totalOptions = {
      showHiddenFees,
      fee: data?.bookingFee,
      type: data?.bookingFeeType,
    };

    const subTotal = calculateTotal(itemsToAdd, totalOptions);
    const lineItemsTotal = calculateTotal(itemsToAdd);

    const referralDiscount = calculateReferralDiscount(
      activeReferralCampaign,
      lineItemsTotal - multibuyDiscount
    );

    const totalPrice = subTotal - multibuyDiscount - referralDiscount;

    const taxRate = data.taxRate;
    const customTaxRate = data.customTaxRate;

    // note if taxes are inclusive then the total does not need taxes to be applied.
    const calculatedTaxes =
      data.taxCalculationMethod === TaxCalculationMethod.Exclusive &&
      taxRate > 0
        ? (lineItemsTotal - multibuyDiscount - referralDiscount) * taxRate
        : 0;
    // note custom taxes are applied when the value is set at the org level on line items.
    const calculatedCustomTaxes =
      customTaxRate > 0 ? lineItemsTotal * customTaxRate : 0;

    const totalBeforeFees = totalPrice;
    const totalFees = calculatedTaxes + calculatedCustomTaxes;
    const totalWithFees = totalBeforeFees + totalFees;

    return {
      totalBeforeFees,
      totalWithFees,
      totalFees,
      lineItemsTotal,
      referralDiscount,
    };
  };

  const onChangeQuantity = (value: number, id: string, zoneId: string) => {
    const ticketType = allTicketTypesAndAddons.find(
      (tt) => tt.id === id && tt.zoneId === zoneId
    );

    actions.updateSelectedTicketTypes({
      id: data.id,
      ticketType,
      zoneId,
      quantity: value,
      checkDepsFunction: (itemsToAdd) => {
        if (isAdmin) return itemsToAdd;

        // Remove any selected entities that no longer meet the purchase restriction
        for (const selectedItem of itemsToAdd) {
          if (
            !isAvailableForSelection(
              itemsToAdd,
              selectedItem,
              ticketsSoldForUser
            ) ||
            selectedItem.quantity <= 0
          ) {
            const index = itemsToAdd.findIndex(
              (item) => item.id === selectedItem.id
            );

            itemsToAdd.splice(index, 1);
          }
        }

        // Display error modal if purchase restrictions fail
        if (value > 0) {
          let success = itemsToAdd.some((item) => item.id === id);

          // Check what user has previously purchased
          if (!success) {
            success = ticketsSoldForUser.some((item) =>
              [item.ticketType?.id, item.membershipType?.id].includes(id)
            );
          }

          if (!success && ticketType?.purchaseRestrictionsHelpText) {
            const purchaseRestrictions =
              ticketType.purchaseRestrictionsHelpText;

            if (purchaseRestrictions.length > 0) {
              const message = (
                <>
                  <Text>{ticketType.name} must be purchased with: </Text>
                  <ul>
                    {purchaseRestrictions.map((name) => (
                      <li key={name}>{name}</li>
                    ))}
                  </ul>
                  {!user && (
                    <Text mt={4}>
                      Already purchased these?
                      <Link
                        ml={"1/2"}
                        textDecoration="underline"
                        cursor="pointer"
                        href={`/login?redirect=${encodeURIComponent(
                          `${router.asPath}?ticket=${ticketType.id}&quantity=${value}`
                        )}`}
                      >
                        Log in
                      </Link>{" "}
                      to buy additional tickets.
                    </Text>
                  )}
                </>
              );

              setAlertError({
                title: "This ticket has purchase restrictions",
                message,
              });
            }
          }
        }

        return itemsToAdd;
      },
      calculateTotals,
    });
  };

  useEffect(() => {
    if (router.query.ticket && allTicketTypes?.length > 0) {
      // If we have a ticket id in the query this will scroll the page to
      // the ticket listing
      const element = document.getElementById(router.query.ticket as string);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  }, [router.query.ticket, allTicketTypes?.length]);

  const handleOrderCreation = async () => {
    setIsSubmitting(true);
    if (selectedTickets.length === 0 && selectedAddons.length === 0) {
      showToast(
        `No ${
          isMembership
            ? `${t("membership_name_plural").toLowerCase()}`
            : ticketVariant
        } selected!`,
        "error"
      );
      return setIsSubmitting(false);
    }

    try {
      await createOrder(
        selectedTickets?.filter((seat) => {
          return seat.quantity > 0;
        }),
        selectedAddons,
        activePromotions?.[0]?.promotion?.id,
        (errorMessage) => {
          setAlertError({
            title: "There was a problem...",
            message: errorMessage,
          });

          setNonSeatedStoreState({
            isSubmitting: false,
          });
          return setIsSubmitting(false);
        }
      );

      // Order is succesfully created so we can reset the reservation store state
      actions.resetInitialState();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (zones?.length > 0) {
      trackViewItemList({
        id: data.id,
        name: data.name,
        zones,
        allTicketTypes: allTicketTypes,
        selectedTicketTypes: selectedTickets,
        ticketsSoldForUser,
        isAdmin,
        viewableAddons,
        release,
      });
    }
  }, [zones.length]);

  const hasTicketSelected =
    selectedTickets?.length > 0 || selectedAddons?.length > 0;

  const {
    totalWithFees: totalPrice,
    lineItemsTotal,
    referralDiscount,
  } = calculateTotals(selectedTicketTypesAndAddons);

  const showIsR18 = data.isR18;

  const SoldOutWaitlistMessage: TSoldOutWaitlistMessage = ({ zoneName }) => {
    const ticketWaitlistLink = `/events/${data.id}/ticket-waitlist${
      release ? `?release=${release.id}` : ""
    }`;

    return (
      <Flex
        justifyContent={"center"}
        p={3}
        backgroundColor="N100"
        mt={2}
        borderRadius="sm"
      >
        <Text variant="extraBold.M">
          {zoneName} sold out –{" "}
          <StyledLink
            onClick={captureClick(
              (e) => {
                e.preventDefault();
                void router.push(ticketWaitlistLink);
              },
              "join-waitlist",
              {
                [AttributeNames.FLICKET_ZONE_NAME]: zoneName,
              }
            )}
            href={ticketWaitlistLink}
          >
            join the waitlist
          </StyledLink>{" "}
          for ticket updates
        </Text>
      </Flex>
    );
  };

  const hasResaleWaitlist = data.ticketResaleWaitlistEnabled ?? false;

  const primaryButtonLabel = (() => {
    if (organization.id === "2648ddb4-b50c-489f-a3eb-ae0249e3db3c") {
      return "Join now";
    }

    return `Buy ${
      organization.landingPage === LandingPage.SingleEvent
        ? "ticket"
        : isMembership
        ? t("membership_name").toLowerCase()
        : ticketVariant
    }s`;
  })();

  const onOrderCreationClick = captureClick(
    () => {
      void handleOrderCreation();
    },
    InteractionNames.BUTTON_PRIMARY,
    {
      [AttributeNames.BUTTON_LABEL]: primaryButtonLabel,
    }
  );

  const isExperienceSales = !hasFeature("LegacyGATicketSelection");

  const ticketTypeVariantTitle = isSinglePage
    ? `${startCase(ticketVariant)}s`
    : isMembership
    ? t("membership_name_plural")
    : `${startCase(ticketVariant)}s`;

  function onClickZone(releaseZoneId) {}

  const visibleZonesAndTickets = useFilterVisibleZonesAndTickets({
    zones,
    selectedTickets,
    ticketsSoldForUser,
    isPresaleRelease: releaseType === ReleaseType.Presale,
    isAdmin,
  });

  return {
    ...props,
    zones,
    visibleZonesAndTickets,
    isExperienceSales,
    delayedIsSubmitting,
    importantNotice,
    showIsR18,
    ticketTypeVariantTitle,
    hasResaleWaitlist,
    showHiddenFees,
    isAdmin,
    selectedTickets,
    selectedAddons,
    releaseType,
    requiresAuth,
    ticketsSoldForUser,
    SoldOutWaitlistMessage,
    showWaitlistMessage:
      hasFeature("ticketResale") && hasResaleWaitlist && !isResaleRelease,
    addonTypes: viewableAddons,
    multibuyDiscount,
    lineItemsTotal,
    activeReferralCampaign,
    hasTicketSelected,
    userCredits,
    user,
    totalPrice,
    isPOS,
    bnplGateways,
    stripeAfterpayGateway,
    paymentPlanSettings: data?.paymentPlanSettings,
    alertError,
    setAlertError,
    isSubmitting,
    primaryButtonLabel,
    isMobile,
    orgCurrency: organization.currency,
    referralDiscount,
    events: {
      onChangeQuantity,
      onOrderCreationClick,
      onClickZone,
    },
  };
};
