import React, { useState } from "react";

import {
  Box,
  Text,
  Flex,
  PrimaryButton,
  TransparentButton,
  Divider,
} from "flicket-ui";

import { Select, Icon } from "~components/common";
import {
  AlcoholFreeZoneTypes,
  CoverTypes,
  seatSelectTypeOptions,
  SeatSelectTypes,
  SortTypes,
} from "~components/reservation/constants";
import {
  CoverType,
  AlcoholFreeZone,
  SortBy,
} from "~components/reservation/options";
import { useFilterOptions } from "~hooks/useFilterOptions";

interface MobileFiltersProps {
  amount: number;
  amountDisabled: boolean;
  setAmount: (value: number) => void;
  coverType: CoverTypes;
  setCoverType: (value: string) => void;
  alcoholFreeZone: AlcoholFreeZoneTypes;
  setAlcoholFreeZone: (value: string) => void;
  selectedSort: SortTypes;
  setSelectedSort: (value: string) => void;
  seatSelectType: SeatSelectTypes;
  setSeatSelectType: (value: string) => void;
  seatAmountOptions: {
    value: number;
    label: string;
  }[];
  filterOptions?: string[];
}

export const MobileFilters = ({
  amount,
  amountDisabled,
  setAmount,
  coverType,
  setCoverType,
  alcoholFreeZone,
  setAlcoholFreeZone,
  selectedSort,
  setSelectedSort,
  seatSelectType,
  setSeatSelectType,
  seatAmountOptions,
  filterOptions,
}: MobileFiltersProps) => {
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => setShowFilters(!showFilters);
  const { hasOption } = useFilterOptions(filterOptions);

  return (
    <>
      {showFilters && (
        <>
          <Box
            d={{ _: "flex", md: "none" }}
            py={1}
            px={2}
            justifyContent="space-between"
            borderBottom="1px solid"
            borderBottomColor="N200"
            borderTop="1px solid"
            borderTopColor="N200"
            backgroundColor={"white"}
          >
            <Box minW="104px" borderRight="1px solid" borderColor="N200" pr={1}>
              <Select
                value={seatAmountOptions.find((opt) => opt.value === amount)}
                options={seatAmountOptions}
                onChange={(e) => setAmount(e.value)}
                hideSelectedOptions
                isDisabled={amountDisabled}
                small
              />
            </Box>
            <Flex alignItems="center">
              <Text mr={1} fontSize={1} fontWeight="medium">
                Show all seat types
              </Text>
              <PrimaryButton
                px="6/4"
                py={"7px" as any}
                bg="P200"
                color="N800"
                w="auto"
                onClick={toggleFilters}
              >
                <Icon icon="filter" fontSize={4} mr="3/4" />
                <Text fontSize={1} fontWeight="medium">
                  Filter
                </Text>
              </PrimaryButton>
            </Flex>
          </Box>

          <Box
            position="fixed"
            top={0}
            left={0}
            width="100vw"
            height="100%"
            maxHeight="100vh"
            overflowY="auto"
            bg="white"
            zIndex="popover"
            px={2}
            py={3}
          >
            <Flex justifyContent="space-between" mb={3}>
              <Text fontSize={6} fontWeight="extraBold" color="N800">
                Filter options
              </Text>
              <TransparentButton onClick={toggleFilters}>
                <Icon icon="close" fontSize={6} />
              </TransparentButton>
            </Flex>
            <Divider />
            <Box w="100%" alignItems="center" zIndex="popover" mt={3}>
              <Text
                fontSize={2}
                color="N800"
                fontWeight="extraBold"
                mr={2}
                whiteSpace="nowrap"
                mb="6/4"
                mt={3}
              >
                I need tickets for
              </Text>
              <Select
                value={seatAmountOptions.find((opt) => opt.value === amount)}
                options={seatAmountOptions}
                onChange={(e) => setAmount(e.value)}
                hideSelectedOptions
              />

              {hasOption("cover") && (
                <>
                  <Text
                    fontSize={2}
                    color="N800"
                    fontWeight="extraBold"
                    whiteSpace="nowrap"
                    mb="6/4"
                    mt={3}
                  >
                    Seat cover
                  </Text>
                  <CoverType
                    changeCoverType={setCoverType}
                    selectedType={coverType}
                  />
                </>
              )}

              {hasOption("alcohol") && (
                <>
                  <Text
                    fontSize={2}
                    color="N800"
                    fontWeight="extraBold"
                    whiteSpace="nowrap"
                    mb="6/4"
                    mt={3}
                  >
                    Alcohol free zone
                  </Text>
                  <AlcoholFreeZone
                    alcoholFreeZone={alcoholFreeZone}
                    changeAlcoholFreeZone={setAlcoholFreeZone}
                  />
                </>
              )}

              <Text
                fontSize={2}
                color="N800"
                fontWeight="extraBold"
                whiteSpace="nowrap"
                mr={1}
                my={3}
              >
                Sort by
              </Text>
              <SortBy
                selectedSort={selectedSort}
                changeSort={setSelectedSort}
              />

              <Text
                fontSize={2}
                color="N800"
                fontWeight="extraBold"
                whiteSpace="nowrap"
                mr={1}
                my={3}
              >
                Seat selection
              </Text>
              <Select
                value={seatSelectTypeOptions.find(
                  (opt) => opt.value === seatSelectType
                )}
                options={seatSelectTypeOptions}
                onChange={(e) => setSeatSelectType(e.value)}
                hideSelectedOptions
              />
            </Box>
            <Box mt={3}>
              <Divider />
              <PrimaryButton mt={3} onClick={toggleFilters}>
                Set Filter
              </PrimaryButton>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
