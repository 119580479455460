import create from "zustand";
import { logger } from "~features/seated-reservation/store/middleware";

const STORE_NAME = "app-store";

export interface AppStore {
  /**
   * @description used to store query params outside of NextJS router
   * to avoid unnecessary re-renders when query params change.
   */
  unsyncedQueryParams: Record<string, string>;
}

const initialState: AppStore = {
  unsyncedQueryParams: {},
};

export const useAppStore = create<AppStore>(
  logger(() => initialState, STORE_NAME)
);
