import React, { useMemo } from "react";
import {
  Box,
  Price,
  Flex,
  Text,
  TransparentButton,
  SystemProps,
} from "flicket-ui";
import styled from "styled-components";

import { Icon } from "~components/common/Icon";
import { SortTypes } from "~components/reservation/constants";

type StyledListItemProps = SystemProps & {
  isActive: boolean;
  isDisabled?: boolean;
};

const StyledListItem = styled(Flex)<StyledListItemProps>`
  padding: 10px 12px 8px 12px;

  justify-content: space-between;

  background-color: ${(p) => p.theme.colors.white};

  border: 1px solid ${(p) => p.theme.colors.N200};
  border-radius: ${(p) => p.theme.radii.sm};

  transition: border-color 0.2s, box-shadow 0.2s;

  cursor: pointer;

  ${(p) =>
    p.isActive &&
    !p.isDisabled &&
    `
    border-color: ${p.theme.colors.P300};
    box-shadow: 0px 9px 39px rgba(0, 0, 0, 0.08),
      0px 2.01027px 8.71116px rgba(0, 0, 0, 0.0575),
      0px 0.598509px 2.59354px rgba(0, 0, 0, 0.0325);
  `}

  &:hover {
    ${(p) =>
      !p.isDisabled &&
      `
    /** @todo add these styles for selected item as well */
    border-color: ${(p) => p.theme.colors.P300};
    box-shadow: 0px 9px 39px rgba(0, 0, 0, 0.08),
      0px 2.01027px 8.71116px rgba(0, 0, 0, 0.0575),
      0px 0.598509px 2.59354px rgba(0, 0, 0, 0.0325);
    `}
  }
`;

const ListItem = ({ zone, handleClick, isActive, pricing }) => {
  return (
    <StyledListItem
      mb={{ _: "6/4", md: 2 }}
      onClick={() => handleClick(zone)}
      isActive={isActive}
      key={zone.id}
    >
      <Flex flexDir="column">
        <Text
          fontSize={3}
          lineHeight="medium"
          fontWeight="extraBold"
          color="N800"
        >
          {zone?.name}
        </Text>
        <Text fontSize={2} lineHeight="medium" color="N600" mb="1/4">
          <Price price={zone?.priceRange?.min ?? pricing?.min} /> -{" "}
          <Price price={zone?.priceRange?.max ?? pricing?.max} />
        </Text>
      </Flex>
      <Flex>
        {zone?.tags?.includes("f") && (
          <Flex
            alignSelf="center"
            alignItems="center"
            borderRight="1px solid"
            borderColor="N200"
            pr="6/4"
            mr={1}
          >
            <Icon fontSize={7} icon="alcohol-free" />
          </Flex>
        )}
        <TransparentButton>
          <Icon fontSize={6} icon="chevron-right" color="P300" />
        </TransparentButton>
      </Flex>
    </StyledListItem>
  );
};

export const PlaceholderListItem = ({ id }) => (
  <StyledListItem
    mb={{ _: "6/4", md: 2 }}
    key={id}
    isDisabled={true}
    isActive={false}
  >
    <Flex w="100%" flexDir="column">
      <Text
        w="40%"
        mt={"1px" as any}
        mb={"3px" as any}
        height="16px"
        bg="N200"
        opacity="0.7"
        borderRadius="sm"
      />
      <Text
        w="70%"
        mt={"1px" as any}
        mb={"3px" as any}
        height="18px"
        bg="N200"
        opacity="0.4"
        borderRadius="sm"
      />
    </Flex>
    <TransparentButton>
      <Icon fontSize={6} icon="chevron-right" color="N200" />
    </TransparentButton>
  </StyledListItem>
);

export const List = ({
  currentSort,
  zones,
  handleClick,
  selected,
  pricing,
  isPlaceholder = false,
}) => {
  const data = Array.isArray(zones) ? zones : zones?.sections;

  const sortedData = useMemo(() => {
    if (currentSort === SortTypes.PRICE) {
      return data
        ?.slice(0)
        ?.sort((a, b) => a?.priceRange?.min - b?.priceRange?.min);
    }

    return data;
  }, [data, currentSort]);

  if (isPlaceholder) {
    return (
      <Box>
        {[...Object(Array(4)).keys()].map((i) => (
          <PlaceholderListItem id={`placeholder-${i}`} key={i} />
        ))}
      </Box>
    );
  }

  return (
    <Box>
      {sortedData?.map((item) => (
        <ListItem
          key={item.name}
          handleClick={handleClick}
          zone={item}
          pricing={pricing}
          isActive={item?.name === selected?.name}
        />
      ))}
    </Box>
  );
};
