/* eslint-disable react/prop-types */

import { Box, Flex, PrimaryButton, Text } from "flicket-ui";
import { ReactNode, useState } from "react";
import {
  setNonSeatedStoreState,
  useNonSeatedReservationStore,
} from "../../store/non-seated-reservation-store";
import { IReleaseTicket } from "../../nonseated-reservation.types";
import { useOrganization } from "~hooks";
import { useCreateGAOrder } from "../../nonseated.hooks";
import { AnimatePresence, motion } from "framer-motion";
import PriceOrFree from "../common/PriceOrFree";
import { animation } from "./ExperienceSalesFooter.animation";
import { ContentModal } from "~components";
import * as actions from "~features/nonseated-reservation/store/actions";

const MotionBox = motion(Box);

type Mode = "has-selection" | "no-selection";

interface IExperienceSalesLayout {
  id: string;
  releaseId?: string;
  membershipId?: string;
  children?: ReactNode;
  hideFooterDuringMode?: Mode[];
  ticketType?: IReleaseTicket;
}

export const ExperienceSalesFooter = ({
  id,
  releaseId,
  membershipId,
  children,
  hideFooterDuringMode,
}: IExperienceSalesLayout) => {
  const { hasFeature, organization } = useOrganization();

  const [submitting, setSubmitting] = useState(false);
  const [alertError, setAlertError] = useState<
    { title: string; message: string | ReactNode } | undefined
  >();

  const handleOrderCreation = useCreateGAOrder({
    releaseId,
    membershipId,
  });

  const selectedTickets = useNonSeatedReservationStore(
    (state) => state.selectedTicketTypes[id] ?? []
  );

  const totals = useNonSeatedReservationStore((state) => state.totals[id]);

  const mode: Mode =
    selectedTickets.length > 0 ? "has-selection" : "no-selection";

  async function handlePrimaryAction() {
    if (mode === "has-selection") {
      setNonSeatedStoreState({
        isSubmitting: true,
      });
      setSubmitting(true);
      await handleOrderCreation(
        selectedTickets.filter((tt) => tt.type === "ticket") ?? [],
        selectedTickets.filter((tt) => tt.type === "addon") ?? [],
        // activePromotions?.[0]?.promotion?.id,
        // TODO: handle promotions
        undefined,
        (errorMessage: string) => {
          console.error(errorMessage);
          setAlertError({
            title: "There was a problem...",
            message: errorMessage,
          });

          // Rset submitting state locally if error
          setSubmitting(false);

          // Reset store submitting state if error
          setNonSeatedStoreState({
            isSubmitting: false,
          });
        }
      );

      // Rset submitting state locally if success
      setSubmitting(false);

      // Order is succesfully created so we can reset the reservation store state
      actions.resetInitialState();
    }
  }

  const showFooter =
    !hideFooterDuringMode?.includes(mode) &&
    !hasFeature("LegacyGATicketSelection");
  const showFees = hasFeature("showHiddenFees");
  const displayPrice = showFees
    ? totals?.totalWithFees
    : totals?.totalBeforeFees;

  // Northern base is having an issue with the footer floating resulting in people missing tickets
  // check to see if Northern base and if so, remove the fixed position
  return (
    <>
      <Box width="100%">
        {children}
        <AnimatePresence>
          {showFooter && (
            <MotionBox
              {...animation}
              position={
                organization?.id === "9c46120b-ba52-4fae-b6ac-1d93ba101ae2"
                  ? undefined
                  : "fixed"
              }
              zIndex="sticky"
              bottom={0}
              right={0}
              left={0}
              width={1}
              bg="white"
              boxShadow="card"
            >
              <Flex
                flex={1}
                alignItems="center"
                justifyContent="space-between"
                px={2}
                h={80}
                maxW="960px"
                mx={"auto" as any}
              >
                <Box>
                  {mode === "has-selection" && (
                    <>
                      <Text variant="header.S">
                        <PriceOrFree price={displayPrice} />
                      </Text>
                      {showFees && <Text variant="small">Including fees</Text>}
                    </>
                  )}
                </Box>

                <PrimaryButton
                  minW={{ sm: 330 }}
                  width={{ _: "50%", sm: "auto" }}
                  onClick={() => {
                    void handlePrimaryAction();
                  }}
                  isLoading={submitting}
                >
                  {mode === "has-selection" ? "Buy now" : "Select"}
                </PrimaryButton>
              </Flex>
            </MotionBox>
          )}
        </AnimatePresence>
      </Box>

      <ContentModal
        isOpen={!!alertError}
        setIsOpen={() => setAlertError(undefined)}
        title={alertError?.title}
      >
        {typeof alertError?.message === "string" ? (
          <div dangerouslySetInnerHTML={{ __html: alertError?.message }} />
        ) : (
          alertError?.message
        )}
      </ContentModal>
    </>
  );
};
