interface GetTicketFeePropertiesReturn {
  ticketFee: number;
  ticketFeeType: string | undefined;
  stadiumLevyFee: number;
}

// Ticket fee properties are different based on it being a membership or event type :(
export const getTicketFeeProperties = (
  ticketType: any
): GetTicketFeePropertiesReturn => {
  return {
    ticketFee: ticketType?.ticketFee ?? ticketType?.bookingFee ?? 0,
    ticketFeeType: ticketType?.ticketFeeType ?? ticketType?.bookingFeeType,
    stadiumLevyFee: ticketType?.stadiumLevyFee ?? 0,
  };
};
