import { Icon, Modal } from "~components/common";
import { Box, Stack, Text, SecondaryButton } from "flicket-ui";
import CustomModal from "~components/CustomModal";

interface QuantitySetValidationModalProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  quantitySet: number;
}

export const QuantitySetValidationModal = ({
  isOpen,
  onClose,
  name,
  quantitySet,
}: QuantitySetValidationModalProps) => {
  return (
    <CustomModal
      isOpen={isOpen}
      close={onClose}
      dialogOverlayProps={{ alignItems: "center" }}
      dialogContentProps={{ maxWidth: "360px" }}
      height={"fit-content"}
      borderRadius={"md"}
    >
      <CustomModal.Header>
        <Text variant="header.S">Invalid ticket quantity</Text>
      </CustomModal.Header>
      <CustomModal.Content>
        <Box position={"relative"} background={"white"} rounded={"sm"}>
          <Stack direction={"vertical"} gap={1}>
            <Text>
              The &apos;{name}&apos; group deal must be purchased in sets of{" "}
              {quantitySet}.
            </Text>
            <Text>Please update your selection to proceed to checkout.</Text>
          </Stack>
        </Box>
      </CustomModal.Content>
      <CustomModal.Footer px={2}>
        <SecondaryButton
          onClick={onClose}
          lineHeight="normal"
          fontWeight="regular"
          color="N600"
          flex="0"
          display="inline"
          verticalAlign={"baseline"}
          target="_blank"
        >
          Close
        </SecondaryButton>
      </CustomModal.Footer>
    </CustomModal>
  );
};
