import React, { ReactNode } from "react";
import { Box, Flex, Text } from "flicket-ui";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

type SeatsIOProps = {
  showOverlay: boolean;
  chart: ReactNode;
};

const Overlay = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.4);

  z-index: ${(p) => p.theme.zIndices.overlay};
`;

export const SeatsIO = ({ chart, showOverlay }: SeatsIOProps) => (
  <>
    {chart || (
      <Flex
        height="100%"
        width="100%"
        maxHeight={{ _: "293px", sm: "480px", md: "100%" }}
        variant="center"
      >
        <Text>Failed to load the seats map.</Text>
      </Flex>
    )}
    <AnimatePresence>
      {showOverlay && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Overlay />
        </motion.div>
      )}
    </AnimatePresence>
  </>
);
