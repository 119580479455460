import useSWR from "swr";
import { ReferralCampaign } from "~graphql/sdk";
import { useSDK } from "~hooks";
import { useReferralCode } from "~hooks/useReferralCode";

/**
 * Get the referral campaign from the referral code and the active event.
 * @param activeEventId The active event ID is the event the user is currently viewing.
 */
export default function useActiveReferralCampaign(activeEventId: string) {
  const { referralCode, referralCodeVersion } = useReferralCode();
  const sdk = useSDK();

  const fetchFromReferralCode = referralCode && referralCodeVersion === "2";
  const fetchFromEventId = referralCode && !referralCodeVersion;

  const getReferralCampaignFromReferralCode = async (referralCode: string) => {
    if (!fetchFromReferralCode) return null;
    return await sdk.getReferralCampaignByReferralCode({
      referralCode,
    });
  };

  const getReferralCampaignByEventId = async (eventId: string) => {
    if (!fetchFromEventId) return null;
    return await sdk.getReferralCampaignByEventId({
      id: eventId,
    });
  };

  const { data: referralCampaignFromReferralCode } = useSWR(
    fetchFromReferralCode ? referralCode : null,
    getReferralCampaignFromReferralCode
  );

  const { data: referralCampaignFromEventId } = useSWR(
    fetchFromEventId && activeEventId ? activeEventId : null,
    getReferralCampaignByEventId
  );

  let data: ReferralCampaign | undefined;

  if (
    fetchFromReferralCode &&
    referralCampaignFromReferralCode?.getReferralCampaignByReferralCode?.event
      ?.id === activeEventId &&
    referralCampaignFromReferralCode?.getReferralCampaignByReferralCode
      ?.isActive
  ) {
    data = referralCampaignFromReferralCode?.getReferralCampaignByReferralCode as ReferralCampaign;
  }

  if (
    fetchFromEventId &&
    referralCampaignFromEventId?.getReferralCampaignByEventId?.isActive
  ) {
    data = referralCampaignFromEventId?.getReferralCampaignByEventId as ReferralCampaign;
  }

  return {
    activeReferralCampaign: data,
  };
}
