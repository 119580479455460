import {
  Box,
  Divider,
  Flex,
  Price,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
} from "flicket-ui";
import { ensurePlainTextFormat } from "@flicket/utils";
import { ReactNode } from "react";
import { ContentModal, Icon } from "~components";
import { getAfterPayLocaleByCurrency } from "~config";
import { BNPLIntegration } from "~features/checkout/checkout.types";
import BuyNowPayLater, {
  AfterpayTeaser,
} from "~features/seated-reservation/components/common/common.BuyNowPayLater";
import {
  Integration,
  MembershipsSoldForUserQuery,
  ReleaseType,
  TicketsSoldForUserQuery,
  ReferralRewardType,
} from "~graphql/sdk";
import { ListItem } from "./non-seated.ListItem";
import {
  EventQuery,
  ImageGalleryItem,
} from "../../../graphql/typed-document-nodes";
import {
  IReleaseTicket,
  IReleaseZone,
} from "~features/nonseated-reservation/nonseated-reservation.types";
import {
  INonSeatedProps,
  TSoldOutWaitlistMessage,
  useViewModel,
} from "~features/nonseated-reservation/components/NonSeatedList/NonSeatedList.useViewModel";
import { DetailsModal } from "~features/nonseated-reservation/components/DetailsModal/DetailsModal";
import { ImageSlider } from "~features/reservation/non-seated/ImageSlider";
import {
  PlainTextRender,
  TextPreviewRender,
} from "~features/nonseated-reservation/components/common/TextRenders/TextRenders";
import { useIntersectZone } from "~features/reservation/non-seated/useIntersection.hook";
import { hasTextSelection } from "../../../lib/helpers/textSelection";
import { ImportantNotice, R18Notice } from "./non-seated.components";
import { updateUnsyncedQueryParams } from "~lib/state-stores/appStore/action.updateUnsyncedQueryParams";
import { useTheme } from "styled-components";
import { Info } from "phosphor-react";
import { PaymentPlanBadge } from "~features/seated-reservation/components/common/common.PaymentPlanBadge";

export const ReservationList = (props: INonSeatedProps) => {
  const {
    data,
    zones,
    visibleZonesAndTickets,
    isExperienceSales,
    delayedIsSubmitting,
    importantNotice,
    showIsR18,
    ticketTypeVariantTitle,
    hasResaleWaitlist,
    showHiddenFees,
    isAdmin,
    selectedTickets,
    selectedAddons,
    releaseType,
    requiresAuth,
    ticketsSoldForUser,
    SoldOutWaitlistMessage,
    showWaitlistMessage,
    addonTypes,
    multibuyDiscount,
    lineItemsTotal,
    activeReferralCampaign,
    hasTicketSelected,
    userCredits,
    user,
    totalPrice,
    isPOS,
    bnplGateways,
    stripeAfterpayGateway,
    paymentPlanSettings,
    isSubmitting,
    primaryButtonLabel,
    alertError,
    setAlertError,
    orgCurrency,
    isMobile,
    referralDiscount,
    events: { onChangeQuantity, onOrderCreationClick, onClickZone },
  } = useViewModel(props);

  const isPOSRelease = releaseType === ReleaseType.Pos;

  if (delayedIsSubmitting) {
    return <DelayedSubmissionMessage />;
  }

  return (
    <Box
      maxW={{ _: "100%", md: isExperienceSales ? "none" : "640px" }}
      m={{ _: 0, md: "0 auto" as any }}
      px={isExperienceSales ? 0 : 2}
    >
      {!isExperienceSales && (
        <>
          {!isPOS && <ImportantNotice value={importantNotice} />}

          {showIsR18 && <R18Notice />}
        </>
      )}

      <Section
        isExperienceSales={isExperienceSales}
        title={
          isExperienceSales ? undefined : `Select ${ticketTypeVariantTitle}`
        }
      >
        <ZoneList
          isExperienceSales={isExperienceSales}
          zones={visibleZonesAndTickets}
          addons={addonTypes}
          hasResaleWaitlist={hasResaleWaitlist}
          showHiddenFees={showHiddenFees}
          handleChange={onChangeQuantity}
          isAdmin={isAdmin}
          selectedTickets={selectedTickets}
          releaseType={releaseType}
          requiresAuth={requiresAuth}
          ticketsSoldForUser={ticketsSoldForUser}
          SoldOutWaitlistMessage={SoldOutWaitlistMessage}
          showWaitlistMessage={showWaitlistMessage}
          onClickZone={onClickZone}
          eventId={data.type === "event" ? data.id : undefined}
        />
      </Section>

      {addonTypes.length > 0 && (
        <Group
          id="add-ons"
          key="add-ons"
          isExperienceSales={isExperienceSales}
          title={isExperienceSales ? "Add-ons" : "Select Addons"}
          description={undefined}
          imageGallery={undefined}
          hasTicketTypes={addonTypes.length > 0}
          isLast={true}
          isPOSRelease={isPOSRelease}
        >
          {!isExperienceSales && <Divider mb={{ _: 2, md: 1 }} />}

          {addonTypes.map((addonType, idx) => {
            const selectedQuantity = selectedAddons?.find(
              (ao) => ao.id === addonType.id
            );

            return (
              <ListItem
                zoneId={"add-ons"}
                key={addonType.id}
                ticketType={addonType}
                handleChange={onChangeQuantity}
                quantity={selectedQuantity?.quantity}
                isFirstItem={idx === 0}
                isLastItem={idx === addonTypes.length}
                isPOSRelease={isPOSRelease}
              />
            );
          })}
        </Group>
      )}

      {PaymentOptions({
        isExperienceSales,
        multibuyDiscount,
        lineItemsTotal,
        referralDiscount,
        hasTicketSelected,
        userCredits,
        user,
        totalPrice,
        bnplGateways,
        stripeAfterpayGateway,
        isSubmitting,
        primaryButtonLabel,
        showHiddenFees,
        orgCurrency,
        isMobile,
        onOrderCreationClick,
        showMultibuyDiscount: multibuyDiscount > 0,
        showGateways: !isPOS && totalPrice - userCredits > 0,
        showReferalCampaign:
          activeReferralCampaign &&
          activeReferralCampaign.referralUserRewardType ===
            ReferralRewardType.Discount,
        showUsedCredits: userCredits > 0 && hasTicketSelected,
        paymentPlanSettings,
      })}

      {/* DON"T REMOVE: 
       When youre on safari the buy tickets button is hidden as the nav bar is at the bottom. 
       We might be able to add bottom padding or use dvh css property */}
      {!isExperienceSales && (
        <>
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      )}

      <ContentModal
        isOpen={!!alertError}
        setIsOpen={() => setAlertError(undefined)}
        title={alertError?.title}
      >
        {typeof alertError?.message === "string" ? (
          <div dangerouslySetInnerHTML={{ __html: alertError?.message }} />
        ) : (
          alertError?.message
        )}
      </ContentModal>
    </Box>
  );
};

function Section({
  id,
  isExperienceSales,
  title,
  children,
}: {
  id?: string;
  isExperienceSales: boolean;
  title: ReactNode;
  children?: ReactNode;
}) {
  const TitleComponent = (() => {
    if (isExperienceSales) {
      return <Text variant="header.M">{title}</Text>;
    }

    return (
      <Text
        color="N800"
        fontWeight="heavy"
        fontSize={{ _: 6, md: 7 }}
        textAlign={{ _: "center", md: "center" }}
        lineHeight={{ _: "normal", md: "high" }}
        marginTop={{ _: 3, md: 0 }}
      >
        {title}
      </Text>
    );
  })();

  return (
    <Box id={id} mt={isExperienceSales ? 0 : 3}>
      <Stack gap={isExperienceSales ? 0 : 2} direction="vertical" mb={3}>
        {title && TitleComponent}
        {children}
      </Stack>
    </Box>
  );
}

function Group({
  id,
  isExperienceSales,
  title,
  description,
  imageGallery,
  onClick,
  children,
  hasTicketTypes,
  isFirst = false,
  isLast = false,
  isPOSRelease,
}: {
  id: string;
  isExperienceSales: boolean;
  title: string;
  description?: string;
  onClick?: () => void;
  children?: ReactNode;
  imageGallery: ImageGalleryItem[];
  hasTicketTypes: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  isPOSRelease: boolean;
}) {
  const theme = useTheme();

  const { ref: titleComponentRef } = useIntersectZone({
    onIntersectTop: () => {
      updateUnsyncedQueryParams({ zoneId: id });
    },
  });

  const { ref: headSpacerRef } = useIntersectZone({
    onIntersectTop: () => {
      updateUnsyncedQueryParams({ zoneId: id });
    },
  });

  const TitleComponent = (() => {
    if (isExperienceSales) {
      const canClick =
        imageGallery?.length > 0 ||
        ensurePlainTextFormat(description)?.length > 0;

      return (
        <Box
          ref={titleComponentRef}
          cursor={canClick ? "pointer" : "auto"}
          onClick={() => {
            if (canClick && !hasTextSelection()) {
              updateUnsyncedQueryParams({ modalId: id });
            }
          }}
          mb={3}
          // Has to be padding for intersection observer to work
          pt={{ _: imageGallery?.length > 0 ? 0 : 3, sm: 3 }}
          css={`
            &:hover {
              .slider-navigation {
                opacity: 0.8;
              }
            }
          `}
        >
          <Stack
            gap={3}
            flexDirection={{ _: "column-reverse", sm: "row" }}
            justifyContent="space-between"
            flex={1}
            alignItems={{ _: "normal", sm: "flex-start" }}
          >
            <Stack direction="vertical" gap={1}>
              <Text
                variant={isPOSRelease ? "header.S" : "header.M"}
                display="flex"
                alignItems="center"
              >
                {title}
                {isPOSRelease && canClick && (
                  <Icon
                    icon={<Info size={16} weight="light" />}
                    ml={1}
                    cursor={"pointer"}
                    onClick={() => {
                      if (canClick && !hasTextSelection()) {
                        updateUnsyncedQueryParams({ modalId: id });
                      }
                    }}
                  />
                )}
              </Text>
              {!isPOSRelease && (
                <TextPreviewRender value={description} lines={3} maxW={"90%"} />
              )}
            </Stack>

            {imageGallery?.length > 0 && !isPOSRelease && (
              <Box
                // Negative margin to extend image to the edge of the card on mobile
                ml={{ _: -2, sm: 0 } as any}
                mr={{ _: -2, sm: 0 } as any}
              >
                <ImageSlider
                  imageGallery={imageGallery}
                  aspectRatio="5 / 2"
                  width={{ _: "100%", sm: 360 }}
                  borderRadius={{ sm: "sm" }}
                  imageProps={{
                    objectFit: "cover",
                    sizes: `
                      (max-width: ${theme.breakpoints.sm768}) 100vw, 
                      (max-width: ${theme.breakpoints.md1024}) 50vw, 
                      30vw
                  `,
                  }}
                  navigationSize="sm"
                />
              </Box>
            )}
          </Stack>
        </Box>
      );
    }

    return (
      <Box>
        <Text
          color="N800"
          fontWeight="heavy"
          fontSize={{ _: 6, md: 7 }}
          textAlign={{ _: "left", md: "left" }}
          lineHeight="normal"
          mb={2}
        >
          {title}
        </Text>
        {description && (
          <Box
            w="100%"
            p={2}
            bg="N100"
            borderRadius="sm"
            border="1px"
            borderColor="N300"
            mb={2}
          >
            <PlainTextRender value={description} />
          </Box>
        )}
      </Box>
    );
  })();

  return (
    <>
      {/* This "spacer" element  is used for the zone intersection so that 
      the trigger point is immediately after the prior zone. */}
      {isExperienceSales && <Box ref={headSpacerRef} pt={isFirst ? 3 : 8} />}
      <Box id={id} mb={isLast ? 8 : isExperienceSales ? 0 : 6}>
        {title && TitleComponent}
        {children}
        <DetailsModal
          id={id}
          imageGallery={imageGallery}
          aspectRatio={"5 / 2"}
          title={title}
          description={description}
          stickySection={({ isSticky }) => (
            <Text
              as="h1"
              variant="header.M"
              px={isSticky ? { _: 2, sm: 4 } : 0}
            >
              {title}
            </Text>
          )}
        />
      </Box>
    </>
  );
}

export function ZoneList({
  zones,
  addons,
  releaseType,
  requiresAuth,
  ticketsSoldForUser,
  selectedTickets,
  showWaitlistMessage,
  isAdmin,
  handleChange,
  showHiddenFees,
  SoldOutWaitlistMessage,
  isExperienceSales,
  onClickZone,
  eventId,
}: {
  handleChange: (value: number, id: string, zoneId: string) => void;
  zones: IReleaseZone[];
  addons: IReleaseTicket[];
  isExperienceSales: boolean;
  isAdmin: boolean;
  requiresAuth: boolean;
  hasResaleWaitlist: boolean;
  selectedTickets: IReleaseTicket[];
  releaseType: ReleaseType;
  showHiddenFees: boolean;
  ticketsSoldForUser:
    | TicketsSoldForUserQuery["ticketsSoldForUser"]
    | MembershipsSoldForUserQuery["membershipsSoldForUser"];
  SoldOutWaitlistMessage: TSoldOutWaitlistMessage;
  showWaitlistMessage: boolean;
  onClickZone: (id: string) => void;
  eventId?: string;
}) {
  const isPOSRelease = releaseType === ReleaseType.Pos;

  // this value has derived from the zone ticketTypes which uses a ResolvedField see backend for more info.
  let soldOutZones = zones?.filter(
    (zone) =>
      zone.ticketTypes?.length > 0 &&
      zone.ticketTypes
        ?.filter((tt) => tt.isActive)
        ?.every((tt) => tt.isSoldOut ?? false)
  );

  // Hack: BIG SKY PBR 2024 - JULY 18-20 (Outlaw)
  // Show waitlist message if at least one ticket type is sold out
  const isBigSkyHack = eventId === "3c7e9396-c775-441d-b720-8925628c80da";

  if (isBigSkyHack) {
    soldOutZones = zones?.filter((zone) =>
      zone.ticketTypes
        ?.filter((tt) => tt.isActive)
        ?.some((tt) => tt.isSoldOut ?? false)
    );
  }

  const list = zones?.map((zone, zoneIdx) => {
    const ticketListItems = zone.ticketTypes.map((ticketType, idx) => {
      const selectedQuantity = selectedTickets?.find(
        (seat) => seat.id === ticketType.id && seat.zoneName === zone?.name
      );

      return (
        <ListItem
          zoneId={zone.id}
          key={ticketType.id}
          ticketType={ticketType}
          showHiddenFees={showHiddenFees}
          handleChange={handleChange}
          quantity={selectedQuantity?.quantity}
          isFirstItem={idx === 0}
          isLastItem={idx === zone.ticketTypes?.length - 1}
          isPOSRelease={isPOSRelease}
        />
      );
    });

    return (
      <Group
        id={zone.id}
        key={zone.id}
        isExperienceSales={isExperienceSales}
        title={zone.name}
        description={zone.description}
        imageGallery={zone.imageGallery}
        hasTicketTypes={ticketListItems.length > 0}
        isLast={!addons?.length && zoneIdx === zones.length - 1}
        isFirst={zoneIdx === 0}
        isPOSRelease={isPOSRelease}
      >
        <>
          {!isExperienceSales && <Divider my={{ _: 2, md: 2 }} />}

          {ticketListItems?.length > 0 && <Box>{ticketListItems}</Box>}

          {showWaitlistMessage &&
            (() => {
              const soldOutZone = soldOutZones?.find((z) => z.id === zone.id);

              const preventWaitlistJoining =
                (requiresAuth && releaseType === ReleaseType.Unlisted) ||
                releaseType === ReleaseType.Pos ||
                releaseType === ReleaseType.Outlet;

              if (!soldOutZone || preventWaitlistJoining) return null;

              let zoneName = soldOutZone.name;

              if (isBigSkyHack) {
                zoneName = soldOutZone.ticketTypes
                  .filter((t) => t.isActive && t.isSoldOut)
                  .map((t) => t.name)
                  .join(", ");
              }

              return (
                <>
                  {!isExperienceSales && <Divider my={{ _: 2, md: 2 }} />}
                  <SoldOutWaitlistMessage zoneName={zoneName} />
                </>
              );
            })()}
        </>
      </Group>
    );
  });

  return <>{list}</>;
}

function DelayedSubmissionMessage() {
  return (
    <Box mx={"1/2"} my={20} px={4}>
      <Spinner size={64} />
      <Text
        mt={4}
        fontSize={4}
        lineHeight="high"
        color="N600"
        textAlign="center"
      >
        We&apos;re just double-checking that you&apos;re not a ticket-buying
        robot in disguise.
      </Text>
      <Text
        mt={1}
        fontSize={4}
        lineHeight="high"
        color="N600"
        textAlign="center"
      >
        {" "}
        Your tickets are nearly ready.
      </Text>
      <Text
        textAlign="center"
        mt={4}
        fontSize={4}
        lineHeight="high"
        color="N600"
        fontWeight={"heavy"}
      >
        Don&apos;t refresh this page.
      </Text>
    </Box>
  );
}

interface IPaymentOptions {
  multibuyDiscount: number;
  lineItemsTotal: number;
  referralDiscount: number;
  hasTicketSelected: boolean;
  userCredits: number;
  user: any;
  totalPrice: number;
  bnplGateways: Partial<Integration>[];
  stripeAfterpayGateway: any;
  paymentPlanSettings: EventQuery["event"]["paymentPlanSettings"];
  isSubmitting: boolean;
  primaryButtonLabel: string;
  showHiddenFees: boolean;
  orgCurrency: string;
  isMobile: boolean;
  onOrderCreationClick: any;

  isExperienceSales: boolean;
  showGateways: boolean;
  showUsedCredits: boolean;
  showReferalCampaign: boolean;
  showMultibuyDiscount: boolean;
}

function PaymentOptions(props: IPaymentOptions) {
  return props.isExperienceSales
    ? ExperienceSalesPaymentOptions(props)
    : NonSeatedPaymentOptions(props);
}

function ExperienceSalesPaymentOptions(props: IPaymentOptions) {
  const {
    multibuyDiscount,
    lineItemsTotal,
    referralDiscount,
    hasTicketSelected,
    userCredits,
    user,
    totalPrice,
    bnplGateways,
    stripeAfterpayGateway,
    showHiddenFees,
    orgCurrency,

    isMobile,

    showGateways,
    showUsedCredits,
    showReferalCampaign,
    showMultibuyDiscount,
    paymentPlanSettings,
  } = props;

  return (
    <>
      {showMultibuyDiscount && (
        <>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text variant="regular">Sub-Total</Text>
            <Text variant="regular">
              <Price price={lineItemsTotal} />
            </Text>
          </Flex>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text variant="regular">Multibuy promotion applied</Text>
            <Text variant="regular">
              <Price price={-multibuyDiscount} />
            </Text>
          </Flex>
          <Divider my={{ _: 3, md: 2 }} />
        </>
      )}

      {showReferalCampaign && (
        <>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text variant="regular">Sub-Total</Text>
            <Text variant="regular">
              <Price price={lineItemsTotal} />
            </Text>
          </Flex>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text variant="regular">Referral discount applied</Text>

            <Text variant="regular">
              <Price price={-referralDiscount} />
            </Text>
          </Flex>
          <Divider my={{ _: 3, md: 2 }} />
        </>
      )}

      {showUsedCredits && (
        <>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text variant="regular">Your credits to be deducted</Text>
            <Text variant="regular">{`- $${user?.credits?.toFixed(2)}`}</Text>
          </Flex>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text variant="regular">
              Total left to pay after deducting credits
            </Text>
            <Text variant="regular">
              {totalPrice - userCredits > 0 ? (
                <Text variant="regular">
                  <Text variant="regular">
                    <Price price={totalPrice - userCredits} />
                  </Text>
                </Text>
              ) : (
                "$0.00"
              )}
            </Text>
          </Flex>
          <Divider my={{ _: 3, md: 2 }} />
        </>
      )}

      {showGateways && (
        <>
          {bnplGateways.map((g) => (
            <Box mt={2} w="100%" key={g.id}>
              <BuyNowPayLater
                totalPrice={totalPrice - userCredits}
                hasTicketSelected={hasTicketSelected}
                showHiddenFees={showHiddenFees}
                isSmallDisplay={isMobile}
                bnplIntegration={g as BNPLIntegration} // This was checked when filtering.
              />
            </Box>
          ))}
          {stripeAfterpayGateway && (
            <Box mt={2}>
              <AfterpayTeaser
                locale={getAfterPayLocaleByCurrency(orgCurrency)}
                currency={orgCurrency ?? "NZD"}
                amount={totalPrice - userCredits}
              />
            </Box>
          )}
          {paymentPlanSettings && !paymentPlanSettings.disabledAt && (
            <PaymentPlanBadge
              maxInstallmentCount={paymentPlanSettings?.scheduleOptions
                ?.map((s) => s?.installmentCount)
                ?.reduce((a, b) => Math.max(a, b))}
            />
          )}
        </>
      )}
      <Box mb={8} />
    </>
  );
}

function NonSeatedPaymentOptions(props: IPaymentOptions) {
  const {
    multibuyDiscount,
    lineItemsTotal,
    referralDiscount,
    hasTicketSelected,
    userCredits,
    user,
    totalPrice,
    bnplGateways,
    stripeAfterpayGateway,
    showHiddenFees,
    orgCurrency,
    isMobile,
    onOrderCreationClick,
    primaryButtonLabel,
    isSubmitting,

    showGateways,
    showUsedCredits,
    showReferalCampaign,
    showMultibuyDiscount,
    paymentPlanSettings,
  } = props;

  return (
    <>
      <Divider mt={{ _: 3, md: 0 }} />

      {showMultibuyDiscount && (
        <>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text fontWeight="medium" fontSize={2} color="N600">
              Sub-Total
            </Text>
            <Price
              price={lineItemsTotal}
              fontWeight="medium"
              fontSize={4}
              lineHeight={"18px" as any}
              color={"black" as any}
            />
          </Flex>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text fontWeight="medium" fontSize={2} color="N600">
              Multibuy promotion applied
            </Text>
            <Price
              price={-multibuyDiscount}
              fontWeight="medium"
              fontSize={4}
              lineHeight={"18px" as any}
              color={"black" as any}
            />
          </Flex>
          <Divider my={{ _: 3, md: 2 }} />
        </>
      )}

      {showReferalCampaign && (
        <>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text fontWeight="medium" fontSize={2} color="N600">
              Sub-Total
            </Text>
            <Price
              price={lineItemsTotal}
              fontWeight="medium"
              fontSize={4}
              lineHeight={"18px" as any}
              color={"black" as any}
            />
          </Flex>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text fontWeight="medium" fontSize={2} color="N600">
              Referral discount applied
            </Text>

            <Price
              price={-referralDiscount}
              fontWeight="medium"
              fontSize={4}
              lineHeight={"18px" as any}
              color={"black" as any}
            />
          </Flex>
          <Divider my={{ _: 3, md: 2 }} />
        </>
      )}

      <Flex mt={2} justifyContent="space-between" w="100%">
        <Text fontWeight="medium" fontSize={2} color="N600">
          Total {showHiddenFees ? "(including booking fees and taxes)" : ""}
        </Text>
        <Text
          fontWeight="medium"
          fontSize={4}
          lineHeight={"18px" as any}
          color={"black" as any}
        >
          {hasTicketSelected ? <Price price={totalPrice} /> : "-"}
        </Text>
      </Flex>

      {showUsedCredits && (
        <>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text fontWeight="medium" fontSize={2} color="N600">
              Your credits to be deducted
            </Text>
            <Text
              fontWeight="medium"
              fontSize={4}
              lineHeight={"18px" as any}
              color={"black" as any}
            >
              {`- $${user?.credits?.toFixed(2)}`}
            </Text>
          </Flex>
          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text fontWeight="medium" fontSize={2} color="N600">
              Total left to pay after deducting credits
            </Text>
            <Text
              fontWeight="medium"
              fontSize={4}
              lineHeight={"18px" as any}
              color={"black" as any}
            >
              {totalPrice - userCredits > 0 ? (
                <Price price={totalPrice - userCredits} />
              ) : (
                "$0.00"
              )}
            </Text>
          </Flex>
        </>
      )}

      {showGateways && (
        <>
          {bnplGateways.length > 0 &&
            bnplGateways.map((g) => (
              <Box mt={2} w="100%" key={g.id}>
                <BuyNowPayLater
                  totalPrice={totalPrice - userCredits}
                  hasTicketSelected={hasTicketSelected}
                  showHiddenFees={showHiddenFees}
                  isSmallDisplay={isMobile}
                  bnplIntegration={g as BNPLIntegration} // This was checked when filtering.
                />
              </Box>
            ))}
          {stripeAfterpayGateway && (
            <AfterpayTeaser
              locale={getAfterPayLocaleByCurrency(orgCurrency)}
              currency={orgCurrency ?? "NZD"}
              amount={totalPrice - userCredits}
            />
          )}
          {
            <PaymentPlanBadge
              maxInstallmentCount={paymentPlanSettings?.scheduleOptions
                ?.map((s) => s?.installmentCount)
                ?.reduce((a, b) => Math.max(a, b))}
            />
          }
        </>
      )}

      <PrimaryButton
        mt={{ _: 2, md: 6 }}
        padding="mobileGutter"
        w="100%"
        onClick={onOrderCreationClick}
        isLoading={isSubmitting}
        disabled={hasTicketSelected === false}
      >
        {primaryButtonLabel}
      </PrimaryButton>
    </>
  );
}
