import {
  Box,
  Divider,
  Flex,
  PrimaryButton,
  RoundedButton,
  SecondaryButton,
  SystemProps,
  Text,
} from "flicket-ui";
import { useContext, useMemo } from "react";
import styled from "styled-components";
import { Icon } from "~components/common/Icon";
import {
  AlcoholFreeZoneTypes,
  CoverTypes,
  SeatSelectTypes,
  SeatsIOSeat,
  SortTypes,
} from "~components/reservation/constants";
import { ReservationActionTypes, ReservationContext } from "~context";
import {
  BNPLIntegration,
  isBNPLIntegrationType,
} from "~features/checkout/checkout.types";
import { EventQuery, MembershipQuery } from "~graphql/sdk";
import { useAccount } from "~hooks";
import { formatSeatLocation } from "~lib";
import { List } from "../actions/actions.List";
import { Pricing } from "../actions/actions.Pricing";

type ActionProps = {
  eventOrMembership: EventQuery["event"] | MembershipQuery["membership"];
  addons: any[];
  changeTickets: any[];
  venueName: string;
  zones: any;
  changingSeatsFee?: number;
  currentSort: SortTypes;
  currentZone: any;
  setCurrentZone: (zone: any) => void;
  currentSection: any;
  setCurrentSection: (section: any) => void;
  navigateCheckout: () => void;
  isNavigating?: boolean;
  getPrice: (seat: SeatsIOSeat) => [number, number];
  type?: "membership" | "event";
  isR18?: boolean;
  priceComp: number | undefined;
  validSeatSelection: boolean;
};

const StyledRoundedButton = styled(RoundedButton)<SystemProps>`
  height: 32px;
  width: 32px;

  display: flex !important;
  align-items: center;
  justify-content: center;

  border: 1px solid ${(p) => p.theme.colors.N300};

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03),
    0px 1.6711px 8px rgba(0, 0, 0, 0.04), 0px 0.893452px 5px rgba(0, 0, 0, 0.03),
    0px 0.500862px 3.63125px rgba(0, 0, 0, 0.02),
    0px 0.266004px 1.92853px rgba(0, 0, 0, 0.015),
    0px 0.11069px 0.802504px rgba(0, 0, 0, 0.01);
`;

const OriginalSeatBox = styled(Box)`
  border-radius: 6px;
  background: ${(p) => p.theme.colors.P100};
  padding: 16px;
`;

export const Actions = ({
  eventOrMembership,
  addons,
  changeTickets,
  venueName,
  zones,
  changingSeatsFee,
  currentSort,
  currentZone,
  setCurrentZone,
  currentSection,
  setCurrentSection,
  navigateCheckout,
  isNavigating,
  getPrice,
  priceComp,
  type = "event",
  isR18 = false,
  validSeatSelection,
}: ActionProps) => {
  const { dispatch, selectFailed } = useContext(ReservationContext);
  const { isAdmin, isPOS } = useAccount();

  const bnplGateways = eventOrMembership?.gateways?.filter(
    (gateway) => !!gateway && isBNPLIntegrationType(gateway.type)
  );

  const onBack = () => {
    if (currentSection) {
      //dispatch({ type: ReservationActionTypes.UNSET_SEATS });
      setCurrentSection(undefined);
    } else if (currentZone || currentZone?.sections?.length === 0) {
      setCurrentZone(undefined);
    }
  };

  let ActionContent = useMemo(
    () => (
      <List
        currentSort={currentSort}
        pricing={currentZone?.priceRange}
        zones={zones}
        handleClick={setCurrentZone}
        selected={undefined}
      />
    ),
    [zones, currentSort, setCurrentZone]
  );

  const memoizedPricing = useMemo(
    () => (
      <Pricing
        bookingFee={eventOrMembership?.bookingFee}
        bookingFeeType={eventOrMembership?.bookingFeeType}
        addons={changeTickets.length > 0 ? undefined : addons}
        type={type}
        bnplGateways={bnplGateways as BNPLIntegration[]}
        changeTickets={changeTickets}
        changingSeatsFee={changingSeatsFee}
        navigate={navigateCheckout}
        isNavigating={isNavigating}
        getPrice={getPrice}
        noSeatsAvailable={selectFailed}
        onBack={onBack}
        priceComp={priceComp}
        validSeatSelection={validSeatSelection}
        paymentPlanSettings={eventOrMembership?.paymentPlanSettings}
      />
    ),
    [
      isAdmin,
      addons,
      changeTickets,
      changingSeatsFee,
      type,
      navigateCheckout,
      getPrice,
      isNavigating,
      selectFailed,
      validSeatSelection,
    ]
  );

  const memoizedSectionList = useMemo(
    () => (
      <List
        currentSort={currentSort}
        pricing={currentZone?.priceRange}
        zones={currentZone}
        selected={currentSection || currentZone}
        handleClick={setCurrentSection}
      />
    ),
    [currentZone, currentSort, currentSection, setCurrentSection]
  );

  if (currentZone && !currentSection) {
    ActionContent =
      currentZone?.sections?.length > 0 ? memoizedSectionList : memoizedPricing;
  } else if (currentSection) {
    ActionContent = memoizedPricing;
  }

  return (
    <Box
      p={{ _: 2, md: 4 }}
      pb={2}
      height={{ _: "calc(100% - 342px)", sm: "calc(100% - 530px)", md: "100%" }}
      maxHeight={{
        _: "calc(100% - 342px)",
        sm: "calc(100% - 530px)",
        md: "100%",
      }}
      overflowY="auto"
      bg={{ _: "N100", md: "transparent" }}
    >
      {isR18 && (
        <Flex
          alignItems="center"
          borderRadius="sm"
          backgroundColor="error25"
          px="6/4"
          py={2}
          mb={2}
        >
          <Icon icon="r18" fontSize={6} mr={1} />
          <Text
            fontWeight="medium"
            fontSize={2}
            lineHeight="medium"
            color="N700"
          >
            This is an R18 event
          </Text>
        </Flex>
      )}
      <Flex mb={{ _: "6/4", md: 2 }} alignItems="center">
        {currentSection ? (
          <StyledRoundedButton
            bg="white"
            onClick={onBack}
            mr="6/4"
            d={{ _: "none", md: "block" }}
            py={0}
          >
            <Icon fontSize={6} icon="chevron-left" />
          </StyledRoundedButton>
        ) : (
          (currentZone || currentZone?.sections?.length === 0) && (
            <StyledRoundedButton
              bg="white"
              onClick={onBack}
              mr="6/4"
              padding={0}
              d={{ _: "none", md: "block" }}
            >
              <Icon fontSize={6} icon="chevron-left" />
            </StyledRoundedButton>
          )
        )}
        <Text fontWeight="heavy" fontSize={4} color="N800">
          {/** @todo: later on let them choose namings aisle vs section */}
          {currentZone?.name} {venueName} {currentZone ? "" : "Zones"}
        </Text>
      </Flex>
      <Divider />
      {!!changeTickets?.length && (
        <OriginalSeatBox mt={2}>
          <Text
            color="N800"
            fontWeight="extraBold"
            fontSize={3}
            lineHeight={"18px" as any}
          >
            Your original seat{changeTickets?.length > 1 ? "s" : ""}
          </Text>
          {changeTickets?.map((changeTicket) => (
            <Text
              key={changeTicket.id}
              fontSize={1}
              lineHeight="high"
              color="N600"
              mt="1/4"
              fontWeight="medium"
            >
              {formatSeatLocation(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                changeTicket.seatZone,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                changeTicket.seatSection,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                changeTicket.seatLabel
              )}
            </Text>
          ))}
        </OriginalSeatBox>
      )}
      <Box py={1} mt={{ _: "1/2", md: 2 }}>
        {zones?.length > 0 ? (
          ActionContent
        ) : (
          <>
            <Text>No options available.</Text>
            <SecondaryButton
              w="100%"
              mt={3}
              onClick={() => {
                dispatch({
                  type: ReservationActionTypes.UPDATE_OPTIONS,
                  payload: {
                    seatSelectType: SeatSelectTypes.QUICK_SELECT,
                    coverType: CoverTypes.ALL,
                    zoneType: AlcoholFreeZoneTypes.NO_PREFERENCE,
                  },
                });
              }}
            >
              Reset filters
            </SecondaryButton>
          </>
        )}
      </Box>
      {currentZone &&
        !currentSection &&
        (currentZone?.sections?.length > 0 ? (
          <>
            <Divider />
            <PrimaryButton
              isLoading={isNavigating}
              mt={2}
              w="100%"
              disabled={validSeatSelection === false && !isAdmin && !isPOS}
              onClick={navigateCheckout}
            >
              {validSeatSelection === true || isAdmin || isPOS
                ? "Buy tickets"
                : "Can not leave a single seat"}
            </PrimaryButton>
          </>
        ) : null)}
    </Box>
  );
};
