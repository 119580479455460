import { Box, Flex, Img, Price, Text } from "flicket-ui";
import { useState } from "react";
import styled from "styled-components";
import { CheckoutBNPLModal } from "~components/checkout/financial/checkout.BNPL.modal";
import { getAfterPayLocaleByCurrency } from "~config";
import { BNPLIntegration } from "~features/checkout/checkout.types";
import { BNPL_CONFIG } from "~features/checkout/constants";
import { IntegrationType } from "~graphql/sdk";
import { useBNPLPriceCalculation, useOrganization } from "~hooks";

interface BuyNowPayLaterProps {
  totalPrice: number;
  hasTicketSelected: boolean;
  bnplIntegration: Partial<BNPLIntegration>;
  showHiddenFees?: boolean;
  isSmallDisplay?: boolean;
}

const BuyNowPayLater = ({
  totalPrice,
  hasTicketSelected,
  bnplIntegration,
  isSmallDisplay = false,
}: BuyNowPayLaterProps) => {
  const [isOpen, setOpen] = useState(false);
  const { organization } = useOrganization();
  const { bnplPrice } = useBNPLPriceCalculation(totalPrice, bnplIntegration);
  const config = BNPL_CONFIG[bnplIntegration?.type];

  const showBNPLPrice = hasTicketSelected && bnplPrice > 0;

  return (
    <Flex
      mt={3}
      onClick={() => setOpen(true)}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      {bnplIntegration.type === IntegrationType.PaymentAfterpay ? (
        <AfterpayTeaser
          locale={getAfterPayLocaleByCurrency(organization.currency)}
          currency={organization?.currency}
          amount={totalPrice}
        />
      ) : (
        <Flex alignItems="center" flexDir="column">
          <Text cursor="pointer" variant="regular">
            Buy now, pay later with
            <Img
              width={80}
              display="inline-block"
              src={`/static/${config?.icon}.svg`}
              ml={1}
              mt={"-4px" as any}
            />
          </Text>

          <Text variant="small" textAlign="center" mt="1/2">
            {config?.terms} {config?.period}ly payments{" "}
            {showBNPLPrice ? (
              <>
                of <Price price={bnplPrice} />
              </>
            ) : (
              ""
            )}
            . Available at checkout
          </Text>

          <CheckoutBNPLModal
            variant={bnplIntegration?.type}
            isOpen={isOpen}
            onClose={() => {
              setOpen(!isOpen);
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default BuyNowPayLater;

const StyledAfterpayTeaser = styled(Box)`
  afterpay-placement {
    --messaging-font-size: 14px;
    --logo-badge-width: 86px;
  }
`;

export function AfterpayTeaser({
  locale,
  currency,
  amount,
}: {
  locale: string;
  currency: string;
  amount: number;
}) {
  return (
    <StyledAfterpayTeaser
      justifyContent="center"
      width="100%"
      textAlign={"center"}
      mt={3}
    >
      <Text as={"span"} variant="regular">
        AfterPay available
      </Text>

      {/* @ts-expect-error - Afterpay placement component */}
      <afterpay-placement
        data-intro-text="Pay in"
        data-locale={locale}
        data-currency={currency}
        data-amount={amount}
        style={{
          padding: "0",
          margin: "0",
        }}
      >
        {/* @ts-expect-error - Afterpay placement component */}
      </afterpay-placement>
    </StyledAfterpayTeaser>
  );
}
