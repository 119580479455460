import { useRouter } from "next/router";
import { CreateOrderFn } from "~hooks";

import { LineItemType, OrderType } from "~graphql/sdk";
import { useReferralCode } from "~hooks/useReferralCode";
import { useCreateOrder } from "~hooks/reservation/useCreateOrder";
import { IReleaseTicket, IReleaseZone } from "./nonseated-reservation.types";
import { useEffect } from "react";

export function useCreateGAOrder({
  releaseId,
  membershipId,
}: {
  releaseId?: string;
  membershipId?: string;
}) {
  const router = useRouter();
  const { referralIdOrCode } = useReferralCode();
  const { createOrderAndWaitForCompletion } = useCreateOrder();

  const handleGAOrderCreation: CreateOrderFn = async (
    items: IReleaseTicket[],
    addonItems: IReleaseTicket[],
    multiBuyId,
    errorCallback
  ) => {
    const type = !membershipId ? "event" : "membership";

    const orderType = Object.values(OrderType).includes(
      router.query.type as OrderType
    )
      ? (router.query.type as OrderType)
      : undefined;

    const releasePasswordId =
      sessionStorage.getItem("releasePasswordId") || undefined;

    const addonLineItems =
      addonItems?.map((addon) => ({
        quantity: addon.quantity,
        type: LineItemType.Addon,
        addonId: addon.id,
      })) || [];

    const input = {
      lineItems: [
        ...items.map((ticket) => ({
          quantity: ticket.quantity,
          ...(type === "event" && {
            ticketTypeId: ticket.id,
            type: LineItemType.Ticket,
          }),
          ...(type === "membership" && {
            membershipTypeId: ticket.id,
            type: LineItemType.Membership,
          }),
          seatZone: ticket.zoneName,
        })),
        ...addonLineItems,
      ],
      multiBuyId,
      orderType,
      ...(releasePasswordId && { releasePasswordId }),
      ...referralIdOrCode,
      ...(type === "event"
        ? {
            releaseId: releaseId,
          }
        : type === "membership"
        ? {
            membershipId: releaseId ?? membershipId,
          }
        : {
            releaseId: releaseId,
          }),
    };

    return await createOrderAndWaitForCompletion(input, errorCallback);
  };

  return handleGAOrderCreation;
}

export function usePOSIdLocalStorage() {
  const router = useRouter();

  useEffect(() => {
    if (router.query.posId) {
      if (localStorage.getItem("posId")) {
        localStorage.removeItem("posId");
        localStorage.setItem("posId", router.query.posId as string);
      } else {
        localStorage.setItem("posId", router.query.posId as string);
      }
    }
  }, [router.query.posId]);
}

export function useQueryFilteredZones(
  data: IReleaseZone[],
  query: string | string[]
) {
  const showAllZones = !query;

  const zones = data?.filter((item) => {
    if (showAllZones) return item;

    const name = normaliseString(item.name);

    if (Array.isArray(query)) {
      return query.map(normaliseString).includes(name);
    }

    return normaliseString(query) === name;
  });

  return zones;
}

function normaliseString(str: string) {
  return str.trim().toLowerCase().replace(/\s/g, "");
}
