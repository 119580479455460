import { Box, SystemProps, Text, TextProps } from "flicket-ui";
import {
  ensurePlainTextFormat,
  ensureRichTextFormat,
  isRichTextOnlyParagraphs,
  isValidRichTextFormat,
} from "@flicket/utils";
import { pick } from "@styled-system/props";

import { SlateRenderer } from "~components";
import { useEffect, useRef, useState } from "react";

type callbackFunc = (
  hasOverflow: boolean,
  options: { hasOverflow: boolean; hasMarkedUpContent: boolean }
) => void;

export function PlainTextRender({
  value,
  variant = "regular",
}: {
  value: string;
  variant?: TextProps["variant"];
}) {
  return (
    <Text variant={variant} whiteSpace="pre-line">
      {ensurePlainTextFormat(value)}
    </Text>
  );
}

export function RichTextRender({ value }: { value: string }) {
  return (
    <SlateRenderer type="experienceSales" value={ensureRichTextFormat(value)} />
  );
}

/**
 * Accepts a string as plaing or rich text and returns a preview of the text with a "more info" link.
 *
 * Both plain text and rich text is squashed into plain text without line breaks.
 *
 */
export function TextPreviewRender({
  value,
  lines = 3,
  variant = "regular",
  showMoreInfo = true,
  onRender,
  ...props
}: {
  value: string;
  lines?: number;
  showMoreInfo?: boolean;
  variant?: "regular" | "small";
  onRender?: callbackFunc;
} & SystemProps) {
  const { hasMoreContent, textRef, plainText } = useTextPreviewHelpers(
    lines,
    value,
    onRender
  );

  if (plainText?.length === 0) return null;

  return (
    <Box {...pick(props)}>
      <Text
        ref={textRef}
        variant={variant}
        css={`
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: ${lines};
          overflow: hidden;
        `}
      >
        {plainText}
      </Text>
      {showMoreInfo && hasMoreContent && (
        <Text variant="small" textDecoration="underline" mt="1/2">
          More info
        </Text>
      )}
    </Box>
  );
}

function hasOverflowText(element: HTMLElement, lines: number, text: string) {
  const span = document.createElement("span");
  span.style.visibility = "hidden";
  span.style.whiteSpace = "nowrap";
  span.style.display = "inline-block";
  span.innerHTML = element.innerHTML;
  document.body.appendChild(span);

  const ghostElementWidth = span.scrollWidth;
  const textWidth = element.offsetWidth * lines;
  const hasOverflow = ghostElementWidth >= textWidth;
  document.body.removeChild(span);

  return hasOverflow;
}

function useTextPreviewHelpers(
  lines: number,
  value: string,
  callback?: callbackFunc
) {
  const [hasMoreContent, setHasMoreContent] = useState<boolean>(undefined);
  const textRef = useRef<HTMLDivElement>(null);

  const plainText = ensurePlainTextFormat(value);

  useEffect(() => {
    if (textRef?.current && hasMoreContent === undefined) {
      const hasOverflow = hasOverflowText(textRef.current, lines, plainText);
      const hasMarkedUpContent =
        isValidRichTextFormat(value) &&
        isRichTextOnlyParagraphs(value) === false;
      const showMore = hasOverflow || hasMarkedUpContent;

      setHasMoreContent(showMore);

      callback?.(showMore, { hasOverflow, hasMarkedUpContent });
    }
  }, [textRef?.current]);

  return {
    textRef,
    plainText,
    hasMoreContent,
  };
}
