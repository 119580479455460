import { log } from "~features/seated-reservation/helpers/stateLogger";
import { useAppStore } from "./app-store";

type Payload = Record<string, string>;

export function updateUnsyncedQueryParams(payload: Payload) {
  log("updateUnsyncedQueryParams", payload);

  const state = useAppStore.getState();

  const url = new URL(window.location.href);

  Object.entries(payload).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });

  history.replaceState({}, "", url);

  useAppStore.setState({
    unsyncedQueryParams: {
      ...state.unsyncedQueryParams,
      ...payload,
    },
  });
}
