import React from "react";
import { Box, Flex, Text } from "flicket-ui";
import { Select } from "~components/common/Select";
import {
  alcoholFreeZoneOptions,
  coverTypeOptions,
  sortOptions,
  seatSelectTypeOptions,
  CoverTypes,
  AlcoholFreeZoneTypes,
  SortTypes,
  SeatSelectTypes,
} from "~components/reservation/constants";
import { useFilterOptions } from "~hooks/useFilterOptions";
import { useRouter } from "next/router";
import { initialState } from "~features/seated-reservation/store";

interface FiltersProps {
  amount: number;
  amountDisabled: boolean;
  setAmount: (value: number) => void;
  coverType: CoverTypes;
  setCoverType: (value: string) => void;
  alcoholFreeZone: AlcoholFreeZoneTypes;
  setAlcoholFreeZone: (value: string) => void;
  selectedSort: SortTypes;
  setSelectedSort: (value: string) => void;
  seatSelectType: SeatSelectTypes;
  setSeatSelectType: (value: string) => void;
  seatAmountOptions: {
    value: number;
    label: string;
  }[];
  filterOptions?: string[];
}

export const Filters = ({
  amount,
  amountDisabled,
  setAmount,
  coverType,
  setCoverType,
  alcoholFreeZone,
  setAlcoholFreeZone,
  selectedSort,
  setSelectedSort,
  seatSelectType,
  setSeatSelectType,
  seatAmountOptions,
  filterOptions,
}: FiltersProps) => {
  const { hasOption } = useFilterOptions(filterOptions);
  const router = useRouter();

  return (
    <Box
      bg="N200"
      d={{ _: "none", md: "flex" }}
      py="6/4"
      px={4}
      w="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <Text
          color="N800"
          fontWeight="demiBold"
          mr={2}
          whiteSpace="nowrap"
          fontSize={2}
        >
          I need tickets for
        </Text>
        <Box minW="124px" mr={2}>
          <Select
            defaultValue={seatAmountOptions.find(
              (opt) => opt.value === initialState.options.amount
            )}
            value={seatAmountOptions.find((opt) => opt.value === amount)}
            options={seatAmountOptions}
            onChange={(e: typeof seatAmountOptions[number]) =>
              setAmount(e.value)
            }
            isDisabled={amountDisabled}
            hideSelectedOptions
            small
          />
        </Box>
        {hasOption("cover") && !router?.query?.membershipId && (
          <>
            <Text
              fontSize={2}
              color="N800"
              mr={1}
              whiteSpace="nowrap"
              fontWeight="demiBold"
            >
              Seat cover
            </Text>
            <Box minW="124px" mr={2}>
              <Select
                value={coverTypeOptions.find((opt) => opt.value === coverType)}
                options={coverTypeOptions}
                onChange={(e: typeof coverTypeOptions[number]) =>
                  setCoverType(e.value)
                }
                hideSelectedOptions
                small
              />
            </Box>
          </>
        )}

        {hasOption("alcohol") && !router?.query?.membershipId && (
          <>
            <Text
              fontSize={2}
              color="N800"
              mr={1}
              whiteSpace="nowrap"
              fontWeight="demiBold"
            >
              Alcohol free zone
            </Text>
            <Box minW="124px" mr={2}>
              <Select
                value={alcoholFreeZoneOptions.find(
                  (opt) => opt.value === alcoholFreeZone
                )}
                options={alcoholFreeZoneOptions}
                onChange={(e: typeof alcoholFreeZoneOptions[number]) =>
                  setAlcoholFreeZone(e.value)
                }
                hideSelectedOptions
                small
                borderRadius="6px"
              />
            </Box>
          </>
        )}
        <Box pl={2} borderLeft="1px solid" borderColor="N300">
          <Text
            fontSize={2}
            color="N800"
            whiteSpace="nowrap"
            mr={1}
            fontWeight="demiBold"
          >
            Sort by
          </Text>
        </Box>
        <Box minW="124px">
          <Select
            value={sortOptions.find((opt) => opt.value === selectedSort)}
            options={sortOptions}
            onChange={(e: typeof sortOptions[number]) =>
              setSelectedSort(e.value)
            }
            hideSelectedOptions
            small
            mr={2}
          />
        </Box>
      </Flex>
      <Flex alignItems="center">
        <Box pl={2} borderColor="N300">
          <Text
            fontSize={2}
            color="N800"
            whiteSpace="nowrap"
            mr={1}
            fontWeight="demiBold"
          >
            Seat selection
          </Text>
        </Box>
        <Box minW="124px">
          <Select
            value={seatSelectTypeOptions.find(
              (opt) => opt.value === seatSelectType
            )}
            options={seatSelectTypeOptions}
            onChange={(e: typeof seatSelectTypeOptions[number]) =>
              setSeatSelectType(e.value)
            }
            hideSelectedOptions
            small
            mr={2}
          />
        </Box>
      </Flex>
    </Box>
  );
};
