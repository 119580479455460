import { log } from "~features/seated-reservation/helpers/stateLogger";
import { useAppStore } from "./app-store";

type Payload = string[];

export function removeUnsyncedQueryParams(payload: Payload) {
  log("removeUnsyncedQueryParams", payload);

  const state = useAppStore.getState();

  const url = new URL(window.location.href);

  const newQuery = Object.fromEntries(
    Object.entries(state.unsyncedQueryParams).filter(([key, value]) => {
      if (payload.includes(key)) {
        url.searchParams.delete(key);
        return false;
      }
      url.searchParams.set(key, value);
      return true;
    })
  );

  history.replaceState({}, "", url);

  useAppStore.setState({
    unsyncedQueryParams: newQuery,
  });
}
