import { Box, Text } from "flicket-ui";
export const PaymentPlanBadge = ({
  maxInstallmentCount,
}: {
  maxInstallmentCount: number;
}) => {
  return (
    <Box textAlign={"center"} w={"100%"} mt={3}>
      <Text variant="regular">Payment plans available</Text>
      <Text variant="small">
        Pay over {maxInstallmentCount} months. Available at checkout.
      </Text>
    </Box>
  );
};
