import { Box, Stack, TertiaryButton } from "flicket-ui";
import { CSSProperties, ReactNode } from "react";
import Modal from "~components/common/Modal";
import { ImageSlider } from "~features/reservation/non-seated/ImageSlider";
import { ImageGalleryFragmentFragment } from "~graphql/sdk";
import { RichTextRender } from "../common/TextRenders/TextRenders";
import { useAppStore } from "~lib/state-stores/appStore/app-store";
import { removeUnsyncedQueryParams } from "~lib/state-stores/appStore/action.removeUnsyncedQueryParams";
import { useTheme } from "styled-components";
import { useRouter } from "next/router";

interface IZoneModal {
  imageGallery: ImageGalleryFragmentFragment[];
  title: string;
  description: string;
  stickySection: ReactNode;
  footer?: ReactNode;
  aspectRatio?: CSSProperties["aspectRatio"];
  id: string;
}

export const DetailsModal = (props: IZoneModal) => {
  const modalId = useAppStore((state) => state.unsyncedQueryParams.modalId);
  return (
    <Modal
      alignModalTop={true}
      isOpen={modalId !== undefined && modalId === props.id}
      close={() => removeUnsyncedQueryParams(["modalId"])}
      maxWidth="100%"
      width="100%"
      clickOutsideToClose={true}
    >
      <ModalContent {...props} />
    </Modal>
  );
};

function ModalContent(props: IZoneModal) {
  const {
    imageGallery,
    title,
    description,
    stickySection,
    footer,
    aspectRatio = "3 / 2",
  } = props;

  const theme = useTheme();

  return (
    <>
      {/* Hack to not have the numeric stepper input autofocused on launch */}
      <TertiaryButton
        autoFocus={true}
        type="button"
        position="absolute"
        opacity={0}
        pointerEvents="none"
      />

      <Modal.Content p={0} pb={6}>
        <Box position="relative" zIndex={1}>
          {imageGallery?.length > 0 && (
            <ImageSlider
              imageGallery={imageGallery}
              aspectRatio={aspectRatio}
              imageProps={{
                objectFit: "contain",
                sizes: `
                  (max-width: ${theme.breakpoints.sm768}) 100vw, 50vw
                `,
              }}
            />
          )}
          <Box px={{ _: 2, sm: 4 }} backgroundColor="white">
            <Stack
              direction="horizontal"
              gap={3}
              justifyContent="space-between"
              flex={1}
              py={4}
              boxShadow={"none"}
            >
              {typeof stickySection === "function"
                ? stickySection({ isSticky: false })
                : stickySection}
            </Stack>
          </Box>

          <Box mt={0} px={{ _: 2, sm: 4 }} backgroundColor="white">
            <RichTextRender value={description} />
          </Box>
        </Box>
      </Modal.Content>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </>
  );
}
