import React from "react";
import { Flex, Text, Price, SystemProps } from "flicket-ui";
import range from "lodash/range";

import styled from "styled-components";
import { Select } from "~components";
import { ListItemDetails } from "./actions.item.ListItemDetails";

const StyledListItem = styled(Flex)<SystemProps>`
  margin-bottom: ${(p) => p.theme.space[3]}px;

  justify-content: space-between;
  align-items: center;
`;

const GET_ADDON_QUANTITIES = (isAdmin) =>
  range(isAdmin ? 101 : 21).map((i) => ({ label: i, value: i }));

export const AddonListItem = ({
  id,
  name,
  price,
  quantity,
  onChange,
  description,
  restrictions,
  isAdmin,
  addonImage,
}) => (
  <StyledListItem mb={{ _: "6/4", md: 3 }}>
    <Flex flexDir="column" mr={1} w="100%">
      <Text fontSize={3} lineHeight="normal" fontWeight="extraBold">
        {name}
      </Text>
      <Text fontSize={2} lineHeight="normal" color="N600" mt="1/4">
        <Price price={price} />
      </Text>
      <ListItemDetails
        name={name}
        description={description}
        restrictions={restrictions}
        ticketImage={addonImage}
        ticketType={{
          price,
          ticketType: {
            id,
            name,
          },
        }}
      />
    </Flex>
    <Flex alignItems="center">
      <Text fontSize={1} lineHeight="normal" color="N600" mr={1}>
        Quantity
      </Text>
      <Select
        minW="96px"
        defaultValue={GET_ADDON_QUANTITIES(isAdmin)[0]}
        options={GET_ADDON_QUANTITIES(isAdmin)}
        onChange={(value) => onChange(value)}
        value={quantity}
        small
      />
    </Flex>
  </StyledListItem>
);
