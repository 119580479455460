import {
  Box,
  Flex,
  Text,
  PrimaryButton,
  RadioGroup,
  Radio,
  Stack,
} from "flicket-ui";
import { Select } from "~components";
import { useIsMobile } from "~hooks";
import {
  AlcoholFreeZoneTypes,
  CoverTypes,
  SortTypes,
} from "~components/reservation/constants";
import { initialState } from "~features/seated-reservation/store";

type OptionsProps = {
  handleClose: () => void;
  setAmount: (amount: number) => void;
  amount: number;
  title?: string;
  startDate?: string;
  endDate?: string;
  location?: string;
  seatAmountOptions: any;
  filterOptions?: string[];
};

// eslint-disable-next-line react/prop-types
export const CoverType = ({ selectedType, changeCoverType }) => (
  <Stack flexDir="column" space={5} width="100%" mb={3}>
    <RadioGroup
      toggle
      value={selectedType}
      onChange={changeCoverType}
      space={5}
    >
      <Radio value={CoverTypes.ALL}>
        <Text>No preference</Text>
      </Radio>
      <Radio value={CoverTypes.COVERED}>
        <Text>Covered</Text>
      </Radio>
      <Radio value={CoverTypes.UNCOVERED}>
        <Text>Uncovered</Text>
      </Radio>
    </RadioGroup>
  </Stack>
);

// eslint-disable-next-line react/prop-types
export const AlcoholFreeZone = ({ alcoholFreeZone, changeAlcoholFreeZone }) => (
  <Stack flexDir="column" space={5} width="100%" mb={2}>
    <RadioGroup
      toggle
      value={alcoholFreeZone}
      onChange={changeAlcoholFreeZone}
      space={5}
    >
      <Radio value={AlcoholFreeZoneTypes.NO_PREFERENCE}>
        <Text whiteSpace="nowrap">No preference</Text>
      </Radio>
      <Radio value={AlcoholFreeZoneTypes.YES}>
        <Text>Yes</Text>
      </Radio>
      <Radio value={AlcoholFreeZoneTypes.NO}>
        <Text>No</Text>
      </Radio>
    </RadioGroup>
  </Stack>
);

// eslint-disable-next-line react/prop-types
export const SortBy = ({ selectedSort, changeSort }) => (
  <Stack flexDir="column" space={5} width="100%" mb={2}>
    <RadioGroup toggle value={selectedSort} onChange={changeSort} space={5}>
      <Radio value={SortTypes.BEST_AVAILABLE}>
        <Text>Best available</Text>
      </Radio>
      <Radio value={SortTypes.PRICE}>
        <Text>Price</Text>
      </Radio>
    </RadioGroup>
  </Stack>
);

export const Options = ({
  handleClose,
  setAmount,
  amount,
  title,
  startDate,
  endDate,
  location,
  seatAmountOptions,
  filterOptions,
}: OptionsProps) => {
  const isMobile = useIsMobile();
  return (
    <Flex flexDir="column" width="100%" p={{ _: 2, md: 0 }}>
      <Box>
        <Text fontWeight="extraBold" color="N800" fontSize={2} mb="6/4">
          I need tickets for
        </Text>
        <Select
          defaultValue={seatAmountOptions.find(
            (opt) => opt.value === (amount ?? initialState.options.amount)
          )}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          onChange={({ value }) => setAmount(value)}
          options={seatAmountOptions}
          mb={5}
          selectProps={{
            // 300 is the default max menu height
            // This fixes a bug on android Chrome
            // where the dropdown does off the bottom viewport
            maxMenuHeight: isMobile ? 150 : 300,
          }}
        />
      </Box>

      <PrimaryButton mt={3} mb={5} onClick={handleClose}>
        Seat Select
      </PrimaryButton>
    </Flex>
  );
};
