import {
  NumericStepper,
  Box,
  Flex,
  Stack,
  Text,
  PrimaryButton,
  SystemProps,
} from "flicket-ui";
import { Badge, Icon } from "~components";
import { IReleaseTicket } from "~features/nonseated-reservation/nonseated-reservation.types";
import PriceWithFee from "~components/reservation/common/common.PriceWithFee";
import { DetailsModal } from "../DetailsModal/DetailsModal";
import PriceOrFree from "../common/PriceOrFree/PriceOrFree";
import { forwardRef, useState } from "react";
import { pick } from "@styled-system/props";
import { TicketAvailabilityStatus } from "~graphql/sdk";
import { ImageSlider } from "~features/reservation/non-seated/ImageSlider";
import { TextPreviewRender } from "../common/TextRenders/TextRenders";
import { useIntersectZone } from "~features/reservation/non-seated/useIntersection.hook";
import { hasTextSelection } from "../../../../lib/helpers/textSelection";
import { updateUnsyncedQueryParams } from "~lib/state-stores/appStore/action.updateUnsyncedQueryParams";
import { removeUnsyncedQueryParams } from "~lib/state-stores/appStore/action.removeUnsyncedQueryParams";
import { useTheme } from "styled-components";
import { Info } from "phosphor-react";

interface INonSeatedListItem {
  zoneId: string;
  ticket: IReleaseTicket;
  onChange: (value: number, ticketId: string, zoneId: string) => void;
  quantity: number;
  showHiddenFees: boolean;
  showHighDemandBadge: boolean;
  onClick?: () => void;
  isFirstItem: boolean;
  isLastItem: boolean;
  isPOSRelease: boolean;
}

const Card = forwardRef(
  (
    props: {
      id: string;
      onClick: () => void;
      children: React.ReactNode;
      hasGalleryItem: boolean;
      isLastItem?: boolean;
    },
    ref
  ) => {
    const { id, onClick, children, hasGalleryItem, isLastItem = false } = props;

    return (
      <Box
        ref={ref}
        id={id}
        borderTop="1px solid"
        borderTopColor={{
          _: hasGalleryItem ? "transparent" : "N200",
          xs: "N200",
        }}
        borderBottom="1px solid"
        borderBottomColor={isLastItem ? "N200" : "transparent"}
        onClick={onClick}
        cursor={onClick ? "pointer" : "auto"}
        css={`
          transition: box-shadow 0.15s;
          -webkit-tap-highlight-color: transparent;
          &:hover {
            .slider-navigation {
              opacity: 0.8;
            }
          }
        `}
      >
        <Flex alignItems="center" py={2} flex={1}>
          {children}
        </Flex>
      </Box>
    );
  }
);

Card.displayName = "Card";

export const ExperienceSalesTicketTypeItem = ({
  ticket,
  zoneId,
  onChange,
  quantity,
  showHiddenFees,
  showHighDemandBadge,
  isFirstItem,
  isLastItem,
  isPOSRelease,
}: INonSeatedListItem) => {
  const theme = useTheme();

  const { ref: intersectionRef } = useIntersectZone(
    {
      onIntersectTop: () => {
        updateUnsyncedQueryParams({ zoneId });
      },
    },
    {
      // target bottom only for these elements
      threshold: 0,
    }
  );

  function handleItemClick() {
    if (hasTextSelection()) return;
    updateUnsyncedQueryParams({ modalId: ticket.id });
  }

  const hasGalleryItem = ticket.imageGallery?.length > 0;
  const availabilityStatus = getAvailabilityStatus(ticket);

  const [canClickItem, setCanClickItem] = useState(hasGalleryItem);

  function handleTextPreviewRender(hasContentOverflow: boolean) {
    setCanClickItem(canClickItem || hasContentOverflow);
  }

  return (
    <>
      <Card
        ref={isLastItem ? intersectionRef : undefined}
        id={ticket.id}
        onClick={canClickItem ? handleItemClick : undefined}
        hasGalleryItem={isPOSRelease ? false : hasGalleryItem}
        isLastItem={isLastItem}
        css={`
          &:hover {
            .slider-navigation {
              opacity: 0.8;
            }
          }
        `}
      >
        <Stack
          gap={2}
          direction={["vertical", "vertical", "horizontal"]}
          justifyContent="space-between"
          flex={1}
        >
          {ticket.imageGallery?.length > 0 && !isPOSRelease && (
            <Box>
              <ImageSlider
                imageGallery={ticket.imageGallery}
                aspectRatio="3 / 2"
                navigationSize="sm"
                borderRadius="sm"
                width={{ _: "100%", xs: 196 }}
                imageProps={{
                  objectFit: "cover",
                  sizes: `
                    (max-width: ${theme.breakpoints.xs640}) 100vw, 
                    (max-width: ${theme.breakpoints.sm768}) 50vw, 
                    20vw
                  `,
                }}
              />
            </Box>
          )}
          <Stack direction="vertical" gap="1/2" flex={1}>
            <Stack
              gap={3}
              direction="horizontal"
              justifyContent="space-between"
            >
              <Stack
                flex={1}
                direction={
                  isPOSRelease
                    ? { _: "vertical", sm: "horizontal" }
                    : "vertical"
                }
                gap="1/2"
                justifyContent="space-between"
                alignContent="center"
              >
                <Text
                  variant={isPOSRelease ? "regular" : "header.XS"}
                  display="flex"
                  alignItems="center"
                >
                  {ticket.name}
                  {isPOSRelease && canClickItem && (
                    <Icon
                      icon={<Info size={16} weight="light" />}
                      ml={1}
                      cursor="pointer"
                      onClick={handleItemClick}
                    />
                  )}
                </Text>
                <Stack
                  gap={["1/2", "1/2", "1/2", 1]}
                  alignItems={{ _: "flex-start", sm: "center" }}
                  direction={{ _: "vertical", sm: "horizontal" }}
                >
                  <TicketPriceAndFee
                    ticket={ticket}
                    showFees={showHiddenFees}
                  />

                  {showHighDemandBadge && !isPOSRelease && (
                    <Badge mb={{ _: "1/2", sm: 0 }}>High demand</Badge>
                  )}
                </Stack>
                {availabilityStatus === "Unavailable" && (
                  <Text variant="small" color="error">
                    All tickets are currently in carts. Please try again later.
                  </Text>
                )}
              </Stack>

              <Box onClick={(e) => e.stopPropagation()}>
                <NumericStepper
                  value={quantity}
                  onChange={(value: number) =>
                    onChange(value, ticket.id, ticket.zoneId)
                  }
                  min={ticket.minPurchaseQuantity}
                  max={ticket.maxPurchaseQuantity}
                  buttonLabel={
                    availabilityStatus === "available"
                      ? "Select"
                      : availabilityStatus
                  }
                  disabled={availabilityStatus !== "available"}
                />
              </Box>
            </Stack>
            {!isPOSRelease && (
              <TextPreviewRender
                value={ticket.description}
                lines={2}
                onRender={handleTextPreviewRender}
                pr={{ _: 0, sm: "140px" } as any}
                variant="small"
              />
            )}
          </Stack>
        </Stack>
      </Card>

      <Modal
        ticketType={ticket}
        quantity={quantity}
        showHiddenFees={showHiddenFees}
        onChange={onChange}
      />
    </>
  );
};

function Modal({
  ticketType,
  showHiddenFees,
  quantity,
  onChange,
}: {
  ticketType: IReleaseTicket;
  showHiddenFees: boolean;
  quantity: number;
  onChange: INonSeatedListItem["onChange"];
}) {
  const [tempQuantity, setTempQuantity] = useState(quantity ?? 1);

  function onSubmit() {
    onChange(tempQuantity, ticketType.id, ticketType.zoneId);
    removeUnsyncedQueryParams(["modalId"]);
  }

  return (
    <DetailsModal
      id={ticketType.id}
      title={ticketType.name}
      description={ticketType.description}
      imageGallery={ticketType.imageGallery}
      aspectRatio={"3 / 2"}
      stickySection={({ isSticky }) => (
        <Stack
          px={isSticky ? { _: 2, sm: 4 } : 0}
          gap={3}
          direction="horizontal"
          justifyContent="space-between"
          flex={1}
          alignItems="center"
        >
          <Box>
            <Text as="h1" variant="header.M">
              {ticketType.name}
            </Text>
            <Text variant="regular">
              <TicketPriceAndFee
                ticket={ticketType}
                showFees={showHiddenFees}
              />
            </Text>
          </Box>
        </Stack>
      )}
      footer={
        <TicketTypeQunatitySelect
          ticketType={ticketType}
          quantity={quantity}
          tempQuantity={tempQuantity}
          onChange={setTempQuantity}
          onSubmit={onSubmit}
        />
      }
    />
  );
}

function TicketTypeQunatitySelect({
  ticketType,
  quantity,
  tempQuantity,
  onChange,
  onSubmit,
}: {
  ticketType: IReleaseTicket;
  quantity: number;
  tempQuantity: number;
  onChange: (q: number) => void;
  onSubmit: () => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [buttonLabel] = useState(
    quantity === undefined ? "Add to order" : "Update order"
  );

  const availabilityStatus = getAvailabilityStatus(ticketType);

  return (
    <>
      <Box>
        <Stack gap={2} alignItems="center">
          <NumericStepper
            value={tempQuantity}
            onChange={(value: number) => onChange(value)}
            min={ticketType.minPurchaseQuantity}
            max={ticketType.maxPurchaseQuantity}
            allowZero={availabilityStatus === "available"}
            disableDecrement={tempQuantity === undefined}
            buttonLabel={
              availabilityStatus === "available" ? "Select" : availabilityStatus
            }
            disabled={availabilityStatus !== "available"}
          />
          {availabilityStatus === "available" && (
            <PrimaryButton
              disabled={isSubmitting}
              w="150px"
              onClick={() => {
                setIsSubmitting(true);
                onSubmit();
              }}
            >
              {buttonLabel}
            </PrimaryButton>
          )}
        </Stack>
      </Box>
    </>
  );
}

function TicketPriceAndFee({
  ticket,
  showFees,
  ...props
}: {
  showFees: boolean;
  ticket: IReleaseTicket;
} & SystemProps) {
  if (ticket.isSoldOut) {
    return null;
  }

  return (
    <Box {...pick(props)}>
      <Text as="span" variant="regular">
        <PriceOrFree price={ticket.price} />
      </Text>
      {showFees && (
        <Text as="span" variant="small">
          {" "}
          <PriceWithFee.Fee showFees={true} ticket={ticket} />
        </Text>
      )}
    </Box>
  );
}

function getAvailabilityStatus(ticket: IReleaseTicket) {
  if (
    ticket.availabilityStatus === TicketAvailabilityStatus.AllocationExhausted
  ) {
    return "Unavailable";
  }

  if (
    ticket.availabilityStatus === TicketAvailabilityStatus.Soldout ||
    ticket.isSoldOut
  ) {
    return "Sold out";
  }

  return "available";
}
