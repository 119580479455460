import { useShowHeaderBanner } from "~components/common/common.HeaderAlertBanner";
import { TABS_HEIGHT } from "./StickyZoneTabs";
import {
  UseIntersectionObserverOptions,
  useIntersectionObserver,
} from "~hooks";

export const SCROLL_IN_PROGRESS = "scroll-in-progress";

export function useIntersectZone(
  props: {
    onIntersectTop?: (entry: IntersectionObserverEntry) => void;
    onIntersectBottom?: (entry: IntersectionObserverEntry) => void;
  },
  options?: Partial<UseIntersectionObserverOptions>
) {
  const { onIntersectTop, onIntersectBottom } = props;
  const { activeBannerHeight } = useShowHeaderBanner();

  const margin = activeBannerHeight + TABS_HEIGHT;

  const ref = useIntersectionObserver({
    // Thresholds are set to 0.05 and 0.95 to mitigate out-of-order intersection events
    // where elements butt up against each other.
    threshold: [0.05, 0.95],
    root: document,
    rootMargin: `-${margin}px 0px 0px 0px`,
    ...options,
    onChange(isIntersecting, entry) {
      if (entry.intersectionRatio <= 0 || !isIntersecting) return;

      const isScrollInProgress = document.body.classList
        .toString()
        .includes(SCROLL_IN_PROGRESS);

      if (isScrollInProgress) return;

      const rootTop = entry.rootBounds.top;
      const rootBottom = entry.rootBounds.height;
      const topBound = entry.boundingClientRect.top;
      const bottomBound = entry.boundingClientRect.bottom;

      if (topBound < rootTop && bottomBound < rootTop) {
        // direction = "outview-top";
        onIntersectTop?.(entry);
      } else if (topBound > rootBottom) {
        // direction = "outview-bottom";
        onIntersectBottom?.(entry);
      } else if (topBound < rootBottom && bottomBound > rootBottom) {
        // direction = "inview-bottom";
        onIntersectBottom?.(entry);
      } else if (topBound < rootTop && bottomBound > rootTop) {
        // direction = "inview-top";
        onIntersectTop?.(entry);
      }
    },
  });

  return {
    ref,
  };
}
