import { Flex, SystemProps, Text, UnderlineButton } from "flicket-ui";
import styled from "styled-components";

import { useAtom } from "jotai";
import { Icon } from "~components";

import { ticketDetailsAtom } from "~features/seated-reservation/components/common/modal.TicketDetails";
import {
  googleAnalyticsMembershipAtom,
  googleAnalyticsReleaseAtom,
} from "~hooks/useGoogleAnalyticsPageView";
import { useOrganization } from "~hooks";
import { IReleaseTicket } from "~features/nonseated-reservation/nonseated-reservation.types";
import { Info } from "phosphor-react";
import { trackViewItem } from "~lib/helpers/googleAnalyticsTracking";

const ToolTip = styled(Flex)<SystemProps>`
  position: relative;
  align-items: center;

  &&& button:hover {
    color: ${(p) => p.theme.colors.N600};
    opacity: 0.8;
    transition: background 0.2s, box-shadow 0.15s, border-color 0.2s, color 0.2s,
      opacity 0.2s;
  }
`;

interface IListItemDetails {
  ticketType: IReleaseTicket;
}

export const ListItemDetails = ({ ticketType }: IListItemDetails) => {
  const { name, restrictions, description, image, price } = ticketType;

  const showDetails = !!(description || image || restrictions);

  if (showDetails === false) {
    return null;
  }

  const { organization } = useOrganization();

  const [, setTicketDetails] = useAtom(ticketDetailsAtom);
  const [release] = useAtom(googleAnalyticsReleaseAtom);
  const [membership] = useAtom(googleAnalyticsMembershipAtom);

  return (
    <ToolTip>
      <UnderlineButton
        color="N600"
        onClick={() => {
          //  @ts-expect-error atom type issue while using strict:false
          setTicketDetails({
            name,
            description,
            restrictions,
            ticketImage: image,
          });
          trackViewItem({
            currency: organization.currency,
            id: release?.event?.id ?? membership?.id,
            name: release?.event?.title ?? membership?.name,
            ticketType,
            isSeated: false,
            releaseName: release?.name,
            releaseId: release?.id,
            price,
          });
        }}
      >
        <Icon color="N800" icon={<Info />} fontSize={3} mr="1/2" />
        <Text variant="small">Details</Text>
      </UnderlineButton>
    </ToolTip>
  );
};

export default ListItemDetails;
