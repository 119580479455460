import { useEffect } from "react";
import { TicketAvailabilityStatus } from "~graphql/sdk";
import { useRouter } from "next/router";
import { useOrganization } from "~hooks";
import { IListItemProps } from "~components/reservation/non-seated";

export const useViewModel = ({
  handleChange,
  ticketType,
  showHiddenFees = false,
  quantity = undefined,
  isPOSRelease,
}: IListItemProps) => {
  const { hasFeature } = useOrganization();
  const isExperienceSales = !hasFeature("LegacyGATicketSelection");
  const query = useRouter().query;

  // If the query has a ticket and quantity, set a timeout to change the quantity selector in the UI
  useEffect(() => {
    if (query.ticket === ticketType.id && query.quantity) {
      const timeoutRef = setTimeout(() => {
        handleChange(
          parseFloat(query.quantity as string),
          ticketType.id,
          ticketType.zoneId
        );
      }, 2000);
      return () => {
        clearTimeout(timeoutRef);
      };
    }
  }, []);

  const showHighDemandBadge =
    ticketType.inHighDemand &&
    ticketType.availabilityStatus === TicketAvailabilityStatus.Available;

  return {
    isExperienceSales,
    showHighDemandBadge,
    showHiddenFees,
    quantity,
    ticket: ticketType,
    events: {
      onQuantityChange: handleChange,
    },
    isPOSRelease,
  };
};
