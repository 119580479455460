import { Flex, SystemProps, Text } from "flicket-ui";
import styled from "styled-components";
import { Select, Option } from "~components";
import { SeatsIOSeat } from "~components/reservation/constants";
import { PricingHookResponse } from "~hooks";
import { formatSeatLocation, getCategoryDataFromLabel } from "~lib/helpers";
import PriceWithFee from "../../common/common.PriceWithFee";
import { ListItemDetails } from "./actions.item.ListItemDetails";
import { FeeType } from "~graphql/sdk";
import { StylesConfig } from "react-select";

const StyledListItem = styled(Flex)<SystemProps>`
  margin-bottom: ${(p) => p.theme.space[3]}px;

  justify-content: space-between;
  align-items: center;
`;

interface ListItemProps {
  seat: SeatsIOSeat;
  options: Option[];
  handleTicketTypeChange: (id: any, newType: any) => void;
  getPrice: PricingHookResponse["getPrice"];
  showHiddenFees?: boolean;
}

export const ListItem = ({
  seat,
  options,
  handleTicketTypeChange,
  getPrice,
  showHiddenFees = false,
}: ListItemProps) => {
  const { zone, section } = getCategoryDataFromLabel(seat?.category?.label);

  const [, originalPrice] = getPrice(seat);

  return (
    <StyledListItem mb={{ _: "5/4", md: 3 }} key={seat?.uuid}>
      <Flex flexDir="column" mr={1}>
        <Text
          fontSize={3}
          lineHeight="normal"
          fontWeight="extraBold"
          color="N700"
        >
          {formatSeatLocation(zone, section, seat?.label)}
        </Text>
        <Text fontSize={2} lineHeight="normal" color="N600" mt="1/4">
          <PriceWithFee
            price={originalPrice}
            showFees={showHiddenFees}
            isSoldOut={seat.ticketType.isSoldOut}
            fee={seat.ticketType.ticketFee}
            isPercentage={seat.ticketType.ticketFeeType === FeeType.Percentage}
            stadiumLevyFee={seat.ticketType.stadiumLevyFee}
          />
        </Text>
        <ListItemDetails
          name={seat?.ticketType?.label}
          description={seat?.ticketType?.description}
          restrictions={seat?.ticketType?.restrictions}
          ticketImage={
            seat?.ticketType?.ticketImage ?? seat?.ticketType?.addonImage
          }
          ticketType={seat}
          price={originalPrice}
        />
      </Flex>
      <Flex minW="128px">
        <Select
          defaultValue={options[0]}
          options={options}
          onChange={(value) => {
            handleTicketTypeChange(seat?.customId ?? seat.id, value);
          }}
          value={seat.ticketType?.id}
          selectProps={{
            isSearchable: false,
            // Menu should be:
            // - At least as wide as the input
            // - No wider than the container/viewport
            // - Right-aligned with the input
            // - As wide as the widest option, within the constraints above
            styles: {
              menu: (provided) => ({
                ...provided,
                width: "max-content",
                position: "absolute",
                right: 0,
                maxWidth: "325px",
                minWidth: "100%",
              }),
              menuList: (provided) => ({
                ...provided,
                width: "max-content",
                maxWidth: "325px",
                minWidth: "100%",
              }),
            } as StylesConfig,
          }}
          small
          useSingleValue
        />
      </Flex>
    </StyledListItem>
  );
};
