import React from 'react';

import {
  DialogContent as ReachDialogContent,
  DialogOverlay as ReachDialogOverlay,
} from '@reach/dialog';
import { Container } from 'flicket-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const DialogOverlay = styled(motion(ReachDialogOverlay))`
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 64px;

  width: 100%;
  height: calc(100% - 64px);

  z-index: 1301;
`;

const DialogContent = styled(motion(ReachDialogContent))`
  max-width: 408px;
  width: 100%;

  /* needed to override the outer padding of the <Container /> */
  & > div {
    padding: 0;
  }
`;

export const FilterModal = ({ isOpen, onClose, children }) => {
  return (
    <DialogOverlay
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      isOpen={isOpen}
      onDismiss={onClose}
    >
      <DialogContent
        aria-label="filter-dialog"
        initial={{
          y: -100,
        }}
        animate={{
          y: 0,
        }}
        exit={{
          y: 100,
        }}
      >
        <Container innerProps={{ padding: 0 }}>{children}</Container>
      </DialogContent>
    </DialogOverlay>
  );
};
