import { Box, SystemProps, Text, UnderlineButton } from "flicket-ui";
import styled from "styled-components";

import { useAtom } from "jotai";
import { Icon } from "~components";

import {
  Details,
  ticketDetailsAtom,
} from "~features/seated-reservation/components/common/modal.TicketDetails";
import {
  googleAnalyticsMembershipAtom,
  googleAnalyticsReleaseAtom,
} from "~hooks/useGoogleAnalyticsPageView";
import { useOrganization } from "~hooks";
import { trackViewItem } from "~lib/helpers/googleAnalyticsTracking";

const ToolTip = styled(Box)<SystemProps>`
  position: relative;

  &&& button:hover {
    color: ${(p) => p.theme.colors.N600};
    opacity: 0.8;
    transition: background 0.2s, box-shadow 0.15s, border-color 0.2s, color 0.2s,
      opacity 0.2s;
  }
`;

type ListItemDetailsProps = Details & {
  ticketType?: any;
  price?: number;
};

export const ListItemDetails = ({
  name,
  description,
  restrictions,
  ticketImage,
  addonImage,
  ticketType,
  price,
}: ListItemDetailsProps) => {
  if (!description && !restrictions) {
    return null;
  }

  const { organization } = useOrganization();

  const [, setTicketDetails] = useAtom(ticketDetailsAtom);
  const [release] = useAtom(googleAnalyticsReleaseAtom);
  const [membership] = useAtom(googleAnalyticsMembershipAtom);

  return (
    <ToolTip>
      <UnderlineButton
        color="N600"
        onClick={() => {
          //  @ts-expect-error atom type issue while using strict:false
          setTicketDetails({
            name,
            description,
            restrictions,
            ticketImage,
            addonImage,
          });
          trackViewItem({
            currency: organization.currency,
            id: release?.event?.id ?? membership?.id,
            name: release?.event?.title ?? membership?.name,
            ticketType,
            isSeated: true,
            releaseName: release?.name,
            releaseId: release?.id,
            price,
          });
        }}
      >
        <Icon color="N500" icon="info" fontSize={3} mr="1/2" />
        <Text fontSize={2} fontWeight="medium" lineHeight="medium">
          Details
        </Text>
      </UnderlineButton>
    </ToolTip>
  );
};

export default ListItemDetails;
