import { Box, Flex, Text } from "flicket-ui";
import { parseAsRichText } from "@flicket/utils";
import { Icon, SlateRenderer } from "~components/common";

export function ImportantNotice({ value }: { value: string }) {
  const text = parseAsRichText(value);
  if (!text) return null;

  return (
    <Box
      w="100%"
      mt={3}
      p={2}
      // Smaller bottom padding to account for the slate renderer's bottom margin on the last element
      pb={1}
      bg="N100"
      borderRadius="sm"
      border="1px"
      borderColor="N200"
    >
      {/* <Flex mb="1/2">
        <Text variant="header.S">Important notes</Text>
      </Flex> */}
      <SlateRenderer value={value} />
    </Box>
  );
}

export function R18Notice() {
  return (
    <Flex
      alignItems="center"
      borderRadius="sm"
      backgroundColor="error25"
      border="1px solid"
      borderColor={"#faece9" as any}
      px="6/4"
      py={2}
      mt={3}
    >
      <Icon icon="r18" fontSize={6} mr={1} />
      <Text fontWeight="medium" fontSize={2} lineHeight="medium" color="N700">
        This is an R18 event
      </Text>
    </Flex>
  );
}
