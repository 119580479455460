import { Flex, Text } from "flicket-ui";
import { Select, Badge } from "~components";
import { FeeType, TicketAvailabilityStatus } from "~graphql/sdk";
import PriceWithFee from "../common/common.PriceWithFee";
import { getQuantityOptions } from "~lib/helpers/getQuantityOptions";
import {
  IReleaseTicket,
  TicketTypeType,
} from "~features/nonseated-reservation/nonseated-reservation.types";
import { useViewModel } from "~features/nonseated-reservation/components/NonSeatedListItem/NonSeatedListItem.useViewModel";
import { ExperienceSalesTicketTypeItem } from "~features/nonseated-reservation/components/NonSeatedListItem/NonSeatedListItem";
import ListItemDetails from "~features/nonseated-reservation/components/NonSeatedListItem/NonSeatedListItem.Details";

export interface IListItemProps {
  zoneId: string;
  ticketType: IReleaseTicket;
  handleChange: (quantity: number, id: string, zoneId: string) => void;
  quantity?: number;
  showHiddenFees?: boolean;
  isFirstItem: boolean;
  isLastItem: boolean;
  isPOSRelease: boolean;
}

export const ListItem = (props: IListItemProps) => {
  const {
    ticket,
    quantity = undefined,
    showHiddenFees,
    showHighDemandBadge,
    isExperienceSales,
    isPOSRelease,
    events: { onQuantityChange },
  } = useViewModel(props);

  if (isExperienceSales) {
    return (
      <ExperienceSalesTicketTypeItem
        zoneId={props.zoneId}
        ticket={ticket}
        onChange={onQuantityChange}
        quantity={quantity}
        showHiddenFees={showHiddenFees}
        showHighDemandBadge={showHighDemandBadge}
        isFirstItem={props.isFirstItem}
        isLastItem={props.isLastItem}
        isPOSRelease={isPOSRelease}
      />
    );
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      mb={3}
      id={ticket.id}
    >
      <Flex flexDir="column" mr={1} w="100%">
        <Text
          fontSize={3}
          lineHeight="normal"
          fontWeight="extraBold"
          color="N800"
        >
          {ticket.name}
        </Text>
        <Text
          fontSize={3}
          fontWeight="medium"
          lineHeight="normal"
          color="N600"
          mt="1/4"
        >
          <PriceWithFee
            price={ticket.price}
            showFees={showHiddenFees}
            isSoldOut={ticket.isSoldOut}
            fee={ticket.ticketFee}
            isPercentage={ticket.ticketFeeType === FeeType.Percentage}
            stadiumLevyFee={ticket.stadiumLevyFee}
          />
        </Text>
        {ticket.availabilityStatus ===
          TicketAvailabilityStatus.AllocationExhausted && (
          <Text
            fontSize={2}
            fontWeight="medium"
            lineHeight="normal"
            color="N600"
            mt="1/2"
          >
            All tickets are currently in carts. Please try again later.
          </Text>
        )}
        <ListItemDetails ticketType={ticket} />
      </Flex>
      <Flex alignItems="center">
        {ticket.availabilityStatus ===
        TicketAvailabilityStatus.AllocationExhausted ? (
          <Text
            fontSize={1}
            lineHeight="normal"
            color="N600"
            minW="98px"
            textAlign="center"
          >
            Allocation exhausted
          </Text>
        ) : ticket.availabilityStatus === TicketAvailabilityStatus.Soldout ||
          ticket.isSoldOut ? (
          <Text
            fontSize={1}
            lineHeight="normal"
            color="N600"
            minW="98px"
            textAlign="center"
          >
            Sold out
          </Text>
        ) : (
          <>
            {showHighDemandBadge && <Badge>In High demand</Badge>}
            <Text fontSize={1} lineHeight="normal" color="N600" mr={1}>
              Quantity
            </Text>
            <Select
              minW="98px"
              options={getQuantityOptions(
                ticket.minPurchaseQuantity,
                ticket.maxPurchaseQuantity
              )}
              value={quantity}
              onChange={(value: number) => {
                onQuantityChange(value, ticket.id, ticket.zoneId);
              }}
              small
              useSingleValue
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
