enum variants {
  Hidden = "hidden",
  visible = "visible",
}

const DURATION = 0.15;

export const variantOptions = {
  [variants.Hidden]: {
    y: 30,
    opacity: 0,
    transition: {
      duration: DURATION,
    },
  },
  [variants.visible]: {
    y: 0,
    opacity: 1,
    transition: {
      duration: DURATION,
      y: { ease: "easeOut" },
    },
  },
};

export const animation = {
  variants: variantOptions,
  initial: variants.Hidden,
  animate: variants.visible,
  exit: variants.Hidden,
};
