import range from "lodash/range";

export const getQuantityOptions = (min: number, max: number) => {
  const result: { label: string | number; value: number }[] = [];

  min = min ? min : 1;
  max = max ? max : 40;

  if (min > 0) {
    result.push({
      label: "-",
      value: 0,
    });
  }

  range(min, max + 1).forEach((i) => {
    result.push({
      label: i,
      value: i,
    });
  });

  return result;
};
