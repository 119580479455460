import {
  Box,
  Divider,
  Flex,
  Price,
  PrimaryButton,
  Spinner,
  Text,
} from "flicket-ui";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  BaseContext,
  ReservationActionTypes,
  ReservationContext,
} from "~context";
import { BNPLIntegration } from "~features/checkout/checkout.types";
import BuyNowPayLater from "~features/seated-reservation/components/common/common.BuyNowPayLater";
import { EventQuery, FeeType, MembershipQuery } from "~graphql/sdk";
import { useAccount, useOrganization } from "~hooks";
import { OrganizationFeatures } from "~lib";
import { getTicketFeeProperties } from "../common/common.utils";
import { AddonListItem, ListItem } from "./item";
import { Option } from "~components/common";
import { SeatsIOSeat } from "../constants";
import { QuantitySetValidationModal } from "../common/common.QuantitySetValidation.modal";
import { PaymentPlanBadge } from "~features/seated-reservation/components/common/common.PaymentPlanBadge";

type EventOrMembership = EventQuery["event"] | MembershipQuery["membership"];

type QuantitySetValidation = {
  count: number;
  quantitySet: number;
  valid: boolean;
  name: string;
};

type PricingProps = {
  bookingFee: EventOrMembership["bookingFee"];
  bookingFeeType: EventOrMembership["bookingFeeType"];
  addons?: any[];
  changingSeatsFee?: number;
  changeTickets: any[];
  navigate: () => void;
  bnplGateways: BNPLIntegration[];
  isNavigating?: boolean;
  getPrice: (seat: SeatsIOSeat) => [number, number];
  type?: "membership" | "event";
  noSeatsAvailable: boolean;
  onBack: () => void;
  priceComp: number | undefined;
  validSeatSelection: boolean;
  paymentPlanSettings: EventOrMembership["paymentPlanSettings"];
};

export const Pricing = ({
  bookingFee,
  bookingFeeType,
  addons: addonOptions,
  changingSeatsFee,
  changeTickets,
  navigate,
  bnplGateways,
  isNavigating,
  getPrice,
  type,
  noSeatsAvailable,
  onBack,
  priceComp,
  validSeatSelection,
  paymentPlanSettings,
}: PricingProps) => {
  const { t } = useOrganization();

  const { seats, addons, dispatch, availableMembershipTypes } = useContext(
    ReservationContext
  );
  const [totalTickets, setTotalTickets] = useState(0);
  const [quantitySetValidationError, setQuantitySetValidationError] = useState<{
    name: string;
    quantitySet: number;
  } | null>(null);
  const { isPOS, isAdmin } = useAccount();

  const { organization, isSinglePage } = useContext(BaseContext);

  const showHiddenFees = !!organization.features?.includes(
    OrganizationFeatures.ShowHiddenFees
  );

  const ticketingOptions: Option[] = availableMembershipTypes.map(
    ({ id, name, ...rest }) => ({
      label: name,
      value: id,
    })
  );

  const availableAddons = useMemo(
    () => addonOptions?.filter(({ isActive }) => isActive),
    [addonOptions]
  );

  const total = useMemo(() => {
    let total = 0;

    seats?.forEach((seat) => {
      let [price] = getPrice(seat);

      const {
        ticketFee,
        ticketFeeType,
        stadiumLevyFee,
      } = getTicketFeeProperties(seat.ticketType);

      if (showHiddenFees && ticketFee > 0) {
        if (ticketFeeType === FeeType.Percentage) {
          price += price * (ticketFee / 100);
        } else {
          price += ticketFee;
        }

        price += stadiumLevyFee;
      }

      total += price;
    });

    availableAddons?.forEach((addon) => {
      const quantity = addons?.[addon.id]?.value;

      if (quantity) {
        total += addon.price * quantity;
      }
    });

    if (priceComp) {
      total -= priceComp;

      if (changingSeatsFee) {
        total += changingSeatsFee;
      }
    }

    if (showHiddenFees && bookingFee > 0) {
      if (bookingFeeType === FeeType.Percentage) {
        total += total * (bookingFee / 100);
      } else {
        total += bookingFee;
      }
    }

    return total;
  }, [
    seats,
    availableAddons,
    addons,
    changingSeatsFee,
    changeTickets,
    getPrice,
  ]);

  const originalTotalPrice = (() => {
    let _total = 0;

    seats?.forEach((seat) => {
      const [, originalPrice] = getPrice(seat);
      _total += originalPrice;
    });

    availableAddons?.forEach((addon) => {
      const quantity = addons?.[addon.id]?.value;

      if (quantity) {
        _total += addon.price * quantity;
      }
    });

    return _total;
  })();

  const multibuyDiscount = seats?.reduce((acc, seat) => {
    const [price, originalPrice] = getPrice(seat);
    return acc + (originalPrice - price);
  }, 0);

  useEffect(() => {
    seats ? setTotalTickets(seats.length) : setTotalTickets(0);
  }, [seats]);

  // update seat with reducer here
  const updateSeat = useCallback(
    (id, ticketId) => {
      const ticketType = availableMembershipTypes.find(
        (v) => v.id === ticketId
      );

      dispatch({
        type: ReservationActionTypes.SET_SEAT_TYPE,
        payload: { id, ticketType },
      });
    },
    [dispatch]
  );

  const updateAddon = useCallback(
    (id, quantity) =>
      dispatch({
        type: ReservationActionTypes.UPDATE_ADDON,
        payload: { id, quantity },
      }),
    [dispatch]
  );

  let pricingOptions;

  if (seats?.length) {
    pricingOptions = seats?.map((seat, i) => (
      <ListItem
        seat={seat}
        handleTicketTypeChange={updateSeat}
        options={ticketingOptions}
        getPrice={getPrice}
        key={i}
        showHiddenFees={showHiddenFees}
      />
    ));
  } else if (type === "membership") {
    pricingOptions = <Text mb={3}>Please select your seating</Text>;
  } else if (noSeatsAvailable) {
    pricingOptions = (
      <>
        <Text mb={3}>Not enough seats available in this zone</Text>
        <PrimaryButton
          onClick={onBack}
          boxShadow="button"
          mt={{ _: "6/4", md: 1 }}
          w="100%"
        >
          Clear selection
        </PrimaryButton>
      </>
    );
  } else {
    pricingOptions = <Spinner size={48} color="P300" mb={2} />;
  }

  const totalPrice = total;

  const onCloseQuantitySetValidationModal = () => {
    setQuantitySetValidationError(null);
  };

  const validateQuantitySets = (
    seats: SeatsIOSeat[],
    setQuantitySetValidationError: (error: {
      name: string;
      quantitySet: number;
    }) => void
  ): boolean => {
    const validationMap = new Map<string, QuantitySetValidation>();

    for (const { ticketType } of seats) {
      if (!ticketType?.quantitySet) continue;

      const currentEntry = validationMap.get(ticketType.id);
      const currentCount = currentEntry?.count || 0;
      const newCount = currentCount + 1;
      const isValid = newCount % ticketType.quantitySet === 0;

      validationMap.set(ticketType.id, {
        count: newCount,
        valid: isValid,
        quantitySet: ticketType.quantitySet,
        name: ticketType.name,
      });
    }

    for (const entry of validationMap.values()) {
      if (!entry.valid) {
        setQuantitySetValidationError({
          name: entry.name,
          quantitySet: entry.quantitySet,
        });
        return false;
      }
    }

    return true;
  };

  const handleBuyButtonClicked = () => {
    if (!seats) return;

    const isValid = validateQuantitySets(seats, setQuantitySetValidationError);

    if (isValid) {
      return navigate();
    }
  };

  return (
    <Box>
      <QuantitySetValidationModal
        isOpen={!!quantitySetValidationError}
        onClose={onCloseQuantitySetValidationModal}
        quantitySet={quantitySetValidationError?.quantitySet}
        name={quantitySetValidationError?.name}
      />
      <Text fontWeight="heavy" fontSize={6} color="N800" lineHeight="normal">
        Your selection
      </Text>

      <Divider my={2} />

      {pricingOptions}

      {!noSeatsAvailable && (seats?.length || type === "membership") && (
        <>
          {!!availableAddons?.length && (
            <Box mb={2} mt={4}>
              <Text
                fontWeight="heavy"
                fontSize={6}
                color="N800"
                lineHeight="normal"
              >
                Available Addons
              </Text>
              <Divider my={2} />
              {availableAddons.map(({ id, name, price, ...rest }) => (
                <AddonListItem
                  {...rest}
                  id={id}
                  key={id}
                  name={name}
                  price={price}
                  quantity={addons?.[id] || 0}
                  onChange={(value) => {
                    updateAddon(id, value);
                  }}
                />
              ))}
            </Box>
          )}

          {!!changeTickets?.length && (
            <Box mb={2}>
              <Divider />
              {!!changingSeatsFee && (
                <Flex mt={2} justifyContent="space-between" w="100%">
                  <Text fontWeight="extraBold" fontSize={3} color="N700">
                    Reservation change fee
                  </Text>
                  <Text fontWeight="medium" fontSize={2} color="N600">
                    <Price price={changingSeatsFee} />
                  </Text>
                </Flex>
              )}
              {!!priceComp && (
                <Flex mt={2} justifyContent="space-between" w="100%">
                  <Text fontWeight="extraBold" fontSize={3} color="N700">
                    Price difference compensation
                  </Text>
                  <Text fontWeight="medium" fontSize={2} color="N600">
                    <Price price={-priceComp} />
                  </Text>
                </Flex>
              )}
            </Box>
          )}

          <Divider />

          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text fontWeight="medium" fontSize={2} color="N600">
              Total tickets
            </Text>
            <Text
              fontWeight="medium"
              fontSize={4}
              lineHeight={"18px" as any}
              color={"black" as any}
            >
              {totalTickets}
            </Text>
          </Flex>

          {multibuyDiscount > 0 && (
            <>
              <Flex mt={2} justifyContent="space-between" w="100%">
                <Text fontWeight="medium" fontSize={2} color="N600">
                  Sub-Total
                </Text>
                <Price
                  price={originalTotalPrice}
                  fontWeight="medium"
                  fontSize={4}
                  lineHeight={"18px" as any}
                  color={"black" as any}
                />
              </Flex>
              <Flex mt={2} justifyContent="space-between" w="100%">
                <Text fontWeight="medium" fontSize={2} color="N600">
                  Multibuy promotion applied
                </Text>
                <Price
                  price={-multibuyDiscount}
                  fontWeight="medium"
                  fontSize={4}
                  lineHeight={"18px" as any}
                  color={"black" as any}
                />
              </Flex>
              <Divider my={{ _: 3, md: 2 }} />
            </>
          )}

          <Flex mt={2} justifyContent="space-between" w="100%">
            <Text fontWeight="medium" fontSize={2} color="N600">
              Total {showHiddenFees ? "(including booking fees)" : ""}
            </Text>
            <Text
              fontWeight="medium"
              fontSize={4}
              lineHeight={"18px" as any}
              color={"black" as any}
            >
              {seats ? <Price price={totalPrice} /> : "-"}
            </Text>
          </Flex>

          {bnplGateways.length > 0 && !isPOS && (
            <Box mt={2} w="100%">
              {bnplGateways.map((g) => (
                <Box mt={2} w="100%" key={g.id}>
                  <BuyNowPayLater
                    totalPrice={totalPrice}
                    hasTicketSelected={!!seats}
                    showHiddenFees={showHiddenFees}
                    isSmallDisplay={true}
                    bnplIntegration={g}
                  />
                </Box>
              ))}
            </Box>
          )}
          {paymentPlanSettings && !paymentPlanSettings.disabledAt && (
            <PaymentPlanBadge
              maxInstallmentCount={paymentPlanSettings?.scheduleOptions
                ?.map((s: { installmentCount: number }) => s?.installmentCount)
                ?.reduce((a, b) => Math.max(a, b))}
            />
          )}

          <PrimaryButton
            boxShadow="button"
            mt={{ _: "6/4", md: 4 }}
            onClick={handleBuyButtonClicked}
            isLoading={isNavigating}
            disabled={
              !seats?.length ||
              (validSeatSelection === false && !isAdmin && !isPOS)
            }
            w="100%"
            fontSize={4}
          >
            {validSeatSelection === true || isAdmin || isPOS
              ? type === "event" || isSinglePage
                ? "Buy tickets"
                : `Buy ${t("membership_name_plural").toLowerCase()}`
              : "Can not leave a single seat"}
          </PrimaryButton>
        </>
      )}
    </Box>
  );
};
